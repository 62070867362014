import { useEffect, useState } from 'react';
import { Link, useLocation, } from 'react-router-dom';
import Button from '../../components/inputs/Button';

import { useAuth } from '../../contexts/AuthContext';
import { useNotifications } from '../../contexts/NotificationContext';
import api from '../../common/api';
import Notice from '../../components/Notice';
import FileInput from '../../components/inputs/FileInput';
import AreYouSure from '../../components/AreYouSure';

import './FilesOverview.scss';
import './Overview.scss';



export default function FilesOverview({assistantId}) {

    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const { user } = useAuth();

    const { addNotification } = useNotifications();

    // for files
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesDefault, setSelectedFilesDefault] = useState(null);
    const [fileSubmissionLoading, setFileSubmissionLoading] = useState(false);
    const [currentFilesMegaBytes, setCurrentFilesMegabytes] = useState(0);
    const [areYouSureShow, setAreYouSureShow] = useState(false);
    const [areYouSureUrl, setAreYouSureUrl] = useState(null);
    const [areYouSureId, setAreYouSureId] = useState(null);

    const handleFileSubmit = (e) => {
        
        e.preventDefault();
        
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file);
        });
    
        api.post(`/assistants/${assistantId}/files`, formData, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}})
            .then(() => {
                api.get(`/assistants/${assistantId}/files`)
                    .then((response) => {
                        setFiles([...response.data.data.files]);
                        setSelectedFiles([]);
                        setSelectedFilesDefault(" ");
                    })
                    .catch(() => {});
            })
            .catch(() => {});
        
    }
 
    const location = useLocation();

    useEffect(() => {

        if (!assistantId) return;

        setLoading(true);
        setFiles([]);
        api.get(`/assistants/${assistantId}/files`)
            .then((response) => {
                setLoading(false);
                const newInstances = [...response.data.data.assistant_files];
                setFiles(newInstances);
                setCurrentFilesMegabytes(newInstances.map(file => file.size_bytes / 1024 / 1024).reduce((c, v) => c + v));
            })
            .catch(() => {
                setLoading(false);
            });

    }, [assistantId]);

    const instanceType = location.pathname.substring(1);

    useEffect(() => {
        setSelectedFilesDefault(null);
        setCurrentFilesMegabytes(selectedFiles.length === 0 ? 0 : selectedFiles.map(file => file.size_bytes / 1024 / 1024).reduce((c, v) => c + v));
    }, [selectedFiles]);

    return (
        <div 
            id="files"
            className={instanceType + " overview"}
            >

            <div className="container">

                <h2>Available files.</h2>
                <p>{files.length ? `${files.length} available` : "There are no files available."}</p>

                <div 
                    id="file-container"
                    className="overview"
                    >

                    {!loading && files.length === 0 &&
                        <Notice
                            title="Notification"
                            message={`Any files uploaded here get a public URL and are accessible from the web.`}
                        />
                    }
                    
                    {files.map((file, key) => {

                        return (
                            <div
                                key={key} 
                                className="file block"
                                >

                                <svg 
                                    className="delete-file"
                                    onClick={() => {
                                        setAreYouSureShow(true);
                                        setAreYouSureUrl(`/assistants/${assistantId}/files/${file.id}/:delete`);
                                        setAreYouSureId(file.id);
                                    }}
                                    width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0 1.104-.896 2-2 2m-9-14c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6-5h-20v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-12-2h4v-1h-4v1z"/></svg>
                                
                                <p><Link className="file-link" to={`https://files.qbud.ai/${file.remote_name}`} target="_blank">{file.remote_name && file.remote_name.split("/")[1]}</Link></p>

                            </div>
                        )
                    })}

                </div>  
 
                <form
                    className="block"
                    >
                    
                    <FileInput
                        id="overview-file-input"
                        maxFiles={user.max_files_n - files.length}
                        maxSizePerFile={2 * 1024 * 1024}
                        allowedExtensions={[".pdf", ".png", ".jpeg", ".jpg"]}
                        onChange={(files) => setSelectedFiles(files)}
                        defaultValueControlled={selectedFilesDefault}
                        />
                    
                    <Button 
                        text="Upload"
                        disabled={selectedFiles.length === 0}
                        loading={fileSubmissionLoading}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 22v2h-10v-2h10zm0-4h-10v2h10v-2zm-10-7v5h10v-5h6l-11-11-11 11h6z"/></svg>}
                        onClick={handleFileSubmit}
                        cta
                        />

                </form>

            </div>
            
            {areYouSureId &&

                <AreYouSure 
                    isOpen={areYouSureShow}
                    setIsOpen={setAreYouSureShow}
                    url={areYouSureUrl}
                    children={
                        <>
                            <h3>Are you sure?</h3>
                            <p>You are about to delete this file. Please note that this cannot be undone and deleted files cannot be recovered.</p>
                        </>
                    }
                    verySure={true}
                    successCallback={() => { 
                        addNotification(`Successfully deleted file.`); 
                        const newFiles = [...files].filter(instance => instance.id !== areYouSureId);
                        setFiles(newFiles);
                    }}
                />

            }

        </div>
        
    )

}