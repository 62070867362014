export default function getDefaultCountries() {
    return [
        {value:'AF',showValue: 'Afghanistan'},
        {value:'AX',showValue: 'Aland Islands'},
        {value:'AL',showValue: 'Albania'},
        {value:'DZ',showValue: 'Algeria'},
        {value:'AS',showValue: 'American Samoa'},
        {value:'AD',showValue: 'Andorra'},
        {value:'AO',showValue: 'Angola'},
        {value:'AI',showValue: 'Anguilla'},
        {value:'AQ',showValue: 'Antarctica'},
        {value:'AG',showValue: 'Antigua And Barbuda'},
        {value:'AR',showValue: 'Argentina'},
        {value:'AM',showValue: 'Armenia'},
        {value:'AW',showValue: 'Aruba'},
        {value:'AU',showValue: 'Australia'},
        {value:'AT',showValue: 'Austria'},
        {value:'AZ',showValue: 'Azerbaijan'},
        {value:'BS',showValue: 'Bahamas'},
        {value:'BH',showValue: 'Bahrain'},
        {value:'BD',showValue: 'Bangladesh'},
        {value:'BB',showValue: 'Barbados'},
        {value:'BY',showValue: 'Belarus'},
        {value:'BE',showValue: 'Belgium'},
        {value:'BZ',showValue: 'Belize'},
        {value:'BJ',showValue: 'Benin'},
        {value:'BM',showValue: 'Bermuda'},
        {value:'BT',showValue: 'Bhutan'},
        {value:'BO',showValue: 'Bolivia'},
        {value:'BA',showValue: 'Bosnia And Herzegovina'},
        {value:'BW',showValue: 'Botswana'},
        {value:'BV',showValue: 'Bouvet Island'},
        {value:'BR',showValue: 'Brazil'},
        {value:'IO',showValue: 'British Indian Ocean Territory'},
        {value:'BN',showValue: 'Brunei Darussalam'},
        {value:'BG',showValue: 'Bulgaria'},
        {value:'BF',showValue: 'Burkina Faso'},
        {value:'BI',showValue: 'Burundi'},
        {value:'KH',showValue: 'Cambodia'},
        {value:'CM',showValue: 'Cameroon'},
        {value:'CA',showValue: 'Canada'},
        {value:'CV',showValue: 'Cape Verde'},
        {value:'KY',showValue: 'Cayman Islands'},
        {value:'CF',showValue: 'Central African Republic'},
        {value:'TD',showValue: 'Chad'},
        {value:'CL',showValue: 'Chile'},
        {value:'CN',showValue: 'China'},
        {value:'CX',showValue: 'Christmas Island'},
        {value:'CC',showValue: 'Cocos (Keeling) Islands'},
        {value:'CO',showValue: 'Colombia'},
        {value:'KM',showValue: 'Comoros'},
        {value:'CG',showValue: 'Congo'},
        {value:'CD',showValue: 'Congo}, Democratic Republic'},
        {value:'CK',showValue: 'Cook Islands'},
        {value:'CR',showValue: 'Costa Rica'},
        {value:'CI',showValue: 'Cote D\'Ivoire'},
        {value:'HR',showValue: 'Croatia'},
        {value:'CU',showValue: 'Cuba'},
        {value:'CY',showValue: 'Cyprus'},
        {value:'CZ',showValue: 'Czech Republic'},
        {value:'DK',showValue: 'Denmark'},
        {value:'DJ',showValue: 'Djibouti'},
        {value:'DM',showValue: 'Dominica'},
        {value:'DO',showValue: 'Dominican Republic'},
        {value:'EC',showValue: 'Ecuador'},
        {value:'EG',showValue: 'Egypt'},
        {value:'SV',showValue: 'El Salvador'},
        {value:'GQ',showValue: 'Equatorial Guinea'},
        {value:'ER',showValue: 'Eritrea'},
        {value:'EE',showValue: 'Estonia'},
        {value:'ET',showValue: 'Ethiopia'},
        {value:'FK',showValue: 'Falkland Islands (Malvinas)'},
        {value:'FO',showValue: 'Faroe Islands'},
        {value:'FJ',showValue: 'Fiji'},
        {value:'FI',showValue: 'Finland'},
        {value:'FR',showValue: 'France'},
        {value:'GF',showValue: 'French Guiana'},
        {value:'PF',showValue: 'French Polynesia'},
        {value:'TF',showValue: 'French Southern Territories'},
        {value:'GA',showValue: 'Gabon'},
        {value:'GM',showValue: 'Gambia'},
        {value:'GE',showValue: 'Georgia'},
        {value:'DE',showValue: 'Germany'},
        {value:'GH',showValue: 'Ghana'},
        {value:'GI',showValue: 'Gibraltar'},
        {value:'GR',showValue: 'Greece'},
        {value:'GL',showValue: 'Greenland'},
        {value:'GD',showValue: 'Grenada'},
        {value:'GP',showValue: 'Guadeloupe'},
        {value:'GU',showValue: 'Guam'},
        {value:'GT',showValue: 'Guatemala'},
        {value:'GG',showValue: 'Guernsey'},
        {value:'GN',showValue: 'Guinea'},
        {value:'GW',showValue: 'Guinea-Bissau'},
        {value:'GY',showValue: 'Guyana'},
        {value:'HT',showValue: 'Haiti'},
        {value:'HM',showValue: 'Heard Island & Mcdonald Islands'},
        {value:'VA',showValue: 'Holy See (Vatican City State)'},
        {value:'HN',showValue: 'Honduras'},
        {value:'HK',showValue: 'Hong Kong'},
        {value:'HU',showValue: 'Hungary'},
        {value:'IS',showValue: 'Iceland'},
        {value:'IN',showValue: 'India'},
        {value:'ID',showValue: 'Indonesia'},
        // {value:'IR',showValue: 'Iran}, Islamic Republic Of'},
        {value:'IQ',showValue: 'Iraq'},
        {value:'IE',showValue: 'Ireland'},
        {value:'IM',showValue: 'Isle Of Man'},
        {value:'IL',showValue: 'Israel'},
        {value:'IT',showValue: 'Italy'},
        {value:'JM',showValue: 'Jamaica'},
        {value:'JP',showValue: 'Japan'},
        {value:'JE',showValue: 'Jersey'},
        {value:'JO',showValue: 'Jordan'},
        {value:'KZ',showValue: 'Kazakhstan'},
        {value:'KE',showValue: 'Kenya'},
        {value:'KI',showValue: 'Kiribati'},
        {value:'KR',showValue: 'Korea'},
        {value:'KW',showValue: 'Kuwait'},
        {value:'KG',showValue: 'Kyrgyzstan'},
        {value:'LA',showValue: 'Lao People\'s Democratic Republic'},
        {value:'LV',showValue: 'Latvia'},
        {value:'LB',showValue: 'Lebanon'},
        {value:'LS',showValue: 'Lesotho'},
        {value:'LR',showValue: 'Liberia'},
        {value:'LY',showValue: 'Libyan Arab Jamahiriya'},
        {value:'LI',showValue: 'Liechtenstein'},
        {value:'LT',showValue: 'Lithuania'},
        {value:'LU',showValue: 'Luxembourg'},
        {value:'MO',showValue: 'Macao'},
        {value:'MK',showValue: 'Macedonia'},
        {value:'MG',showValue: 'Madagascar'},
        {value:'MW',showValue: 'Malawi'},
        {value:'MY',showValue: 'Malaysia'},
        {value:'MV',showValue: 'Maldives'},
        {value:'ML',showValue: 'Mali'},
        {value:'MT',showValue: 'Malta'},
        {value:'MH',showValue: 'Marshall Islands'},
        {value:'MQ',showValue: 'Martinique'},
        {value:'MR',showValue: 'Mauritania'},
        {value:'MU',showValue: 'Mauritius'},
        {value:'YT',showValue: 'Mayotte'},
        {value:'MX',showValue: 'Mexico'},
        {value:'FM',showValue: 'Micronesia}, Federated States Of'},
        {value:'MD',showValue: 'Moldova'},
        {value:'MC',showValue: 'Monaco'},
        {value:'MN',showValue: 'Mongolia'},
        {value:'ME',showValue: 'Montenegro'},
        {value:'MS',showValue: 'Montserrat'},
        {value:'MA',showValue: 'Morocco'},
        {value:'MZ',showValue: 'Mozambique'},
        {value:'MM',showValue: 'Myanmar'},
        {value:'NA',showValue: 'Namibia'},
        {value:'NR',showValue: 'Nauru'},
        {value:'NP',showValue: 'Nepal'},
        {value:'NL',showValue: 'Netherlands'},
        {value:'AN',showValue: 'Netherlands Antilles'},
        {value:'NC',showValue: 'New Caledonia'},
        {value:'NZ',showValue: 'New Zealand'},
        {value:'NI',showValue: 'Nicaragua'},
        {value:'NE',showValue: 'Niger'},
        {value:'NG',showValue: 'Nigeria'},
        {value:'NU',showValue: 'Niue'},
        {value:'NF',showValue: 'Norfolk Island'},
        {value:'MP',showValue: 'Northern Mariana Islands'},
        {value:'NO',showValue: 'Norway'},
        {value:'OM',showValue: 'Oman'},
        {value:'PK',showValue: 'Pakistan'},
        {value:'PW',showValue: 'Palau'},
        {value:'PS',showValue: 'Palestinian Territory}, Occupied'},
        {value:'PA',showValue: 'Panama'},
        {value:'PG',showValue: 'Papua New Guinea'},
        {value:'PY',showValue: 'Paraguay'},
        {value:'PE',showValue: 'Peru'},
        {value:'PH',showValue: 'Philippines'},
        {value:'PN',showValue: 'Pitcairn'},
        {value:'PL',showValue: 'Poland'},
        {value:'PT',showValue: 'Portugal'},
        {value:'PR',showValue: 'Puerto Rico'},
        {value:'QA',showValue: 'Qatar'},
        {value:'RE',showValue: 'Reunion'},
        {value:'RO',showValue: 'Romania'},
        // {value:'RU',showValue: 'Russian Federation'},
        {value:'RW',showValue: 'Rwanda'},
        {value:'BL',showValue: 'Saint Barthelemy'},
        {value:'SH',showValue: 'Saint Helena'},
        {value:'KN',showValue: 'Saint Kitts And Nevis'},
        {value:'LC',showValue: 'Saint Lucia'},
        {value:'MF',showValue: 'Saint Martin'},
        {value:'PM',showValue: 'Saint Pierre And Miquelon'},
        {value:'VC',showValue: 'Saint Vincent And Grenadines'},
        {value:'WS',showValue: 'Samoa'},
        {value:'SM',showValue: 'San Marino'},
        {value:'ST',showValue: 'Sao Tome And Principe'},
        {value:'SA',showValue: 'Saudi Arabia'},
        {value:'SN',showValue: 'Senegal'},
        {value:'RS',showValue: 'Serbia'},
        {value:'SC',showValue: 'Seychelles'},
        {value:'SL',showValue: 'Sierra Leone'},
        {value:'SG',showValue: 'Singapore'},
        {value:'SK',showValue: 'Slovakia'},
        {value:'SI',showValue: 'Slovenia'},
        {value:'SB',showValue: 'Solomon Islands'},
        {value:'SO',showValue: 'Somalia'},
        {value:'ZA',showValue: 'South Africa'},
        {value:'GS',showValue: 'South Georgia And Sandwich Isl.'},
        {value:'ES',showValue: 'Spain'},
        {value:'LK',showValue: 'Sri Lanka'},
        {value:'SD',showValue: 'Sudan'},
        {value:'SR',showValue: 'SurishowValue'},
        {value:'SJ',showValue: 'Svalbard And Jan Mayen'},
        {value:'SZ',showValue: 'Swaziland'},
        {value:'SE',showValue: 'Sweden'},
        {value:'CH',showValue: 'Switzerland'},
        {value:'SY',showValue: 'Syrian Arab Republic'},
        {value:'TW',showValue: 'Taiwan'},
        {value:'TJ',showValue: 'Tajikistan'},
        {value:'TZ',showValue: 'Tanzania'},
        {value:'TH',showValue: 'Thailand'},
        {value:'TL',showValue: 'Timor-Leste'},
        {value:'TG',showValue: 'Togo'},
        {value:'TK',showValue: 'Tokelau'},
        {value:'TO',showValue: 'Tonga'},
        {value:'TT',showValue: 'Trinidad And Tobago'},
        {value:'TN',showValue: 'Tunisia'},
        {value:'TR',showValue: 'Turkey'},
        {value:'TM',showValue: 'Turkmenistan'},
        {value:'TC',showValue: 'Turks And Caicos Islands'},
        {value:'TV',showValue: 'Tuvalu'},
        {value:'UG',showValue: 'Uganda'},
        {value:'UA',showValue: 'Ukraine'},
        {value:'AE',showValue: 'United Arab Emirates'},
        {value:'GB',showValue: 'United Kingdom'},
        {value:'US',showValue: 'United States'},
        {value:'UM',showValue: 'United States Outlying Islands'},
        {value:'UY',showValue: 'Uruguay'},
        {value:'UZ',showValue: 'Uzbekistan'},
        {value:'VU',showValue: 'Vanuatu'},
        {value:'VE',showValue: 'Venezuela'},
        {value:'VN',showValue: 'Vietnam'},
        {value:'VG',showValue: 'Virgin Islands}, British'},
        {value:'VI',showValue: 'Virgin Islands}, U.S.'},
        {value:'WF',showValue: 'Wallis And Futuna'},
        {value:'EH',showValue: 'Western Sahara'},
        {value:'YE',showValue: 'Yemen'},
        {value:'ZM',showValue: 'Zambia'},
        {value:'ZW',showValue: 'Zimbabwe'}
    ];
};