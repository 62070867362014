import { useEffect, useState } from 'react';
import api from '../../common/api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useNotifications } from '../../contexts/NotificationContext';

import './IntegrationsOverview.scss';
import './Overview.scss';

const vendorLogoMap = {
    "Sendcloud": require("../../assets/sendcloud-logo.png"),
    "DHL": require("../../assets/dhl-logo.png"),
    "HubSpot": require("../../assets/hubspot-logo.png"),
    "WooCommerce": require("../../assets/woocommerce-logo.png"),
    // "Shopify": require("../../assets/shopify-logo.png"),
};

const vendorSpelling = {
    "dhl": "DHL",
    "sendcloud": "Sendcloud",
    "hubspot": "HubSpot",
    "zenchef": "Zenchef",
    "woocommerce": "WooCommerce",
    "shopify": "Shopify"
}

export default function IntegrationsOverview({assistantId}) {
    
    const [integrations, setIntegrations] = useState(null);
    const [userIntegrations, setUserIntegrations] = useState(null);
    const [statusIntegrations, setStatusIntegrations] = useState({"Live": [], "Available": []});

    const [search, setSearch] = useSearchParams();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();

    useEffect(() => {

        if (!assistantId) return;

        api.get(`/assistants/${assistantId}/integrations`).then(response => {
            setUserIntegrations(response.data.data.assistant_integrations);
        }).catch(() => {});

        api.get("/integrations").then(response => {
            setIntegrations(response.data.data.integrations);
        }).catch(() => {});

        if (search.get("updated_vendor") && Object.keys(vendorSpelling).includes(search.get("updated_vendor"))) {
            addNotification(`Updated integration for ${vendorSpelling[search.get("updated_vendor")]}.`);
            navigate(window.location.pathname + "?tab=integrations");
        }

    }, [assistantId]);
    
    useEffect(() => {

        if (integrations && integrations.length && userIntegrations !== null) {
            setStatusIntegrations({
                "Live": integrations.filter(integration => userIntegrations.some(userIntegration => userIntegration.integration_id === integration.id)),
                "Available": integrations.filter(integration => !(userIntegrations.some(userIntegration => userIntegration.integration_id === integration.id)))
            })
        }

    }, [integrations, userIntegrations]);

    return (

        <div 
            id="integrations"
            className="overview"
            >

            <div className="container">

                {Object.keys(statusIntegrations).map((status, statusKey) => {

                    return (
                        <>
                            <h2>{status}</h2>
                            
                            {statusIntegrations[status].length === 0 &&
                                <p>There are no {status.toLowerCase()} integrations.</p>
                            }

                            {statusIntegrations[status].length > 0 && 

                                <>
                                    <p>{statusIntegrations[status].length} {status.toLowerCase()}</p>
                                    <div className="integrations-wrapper">

                                        {statusIntegrations[status].map(integration => {
                                            return (
                                                <Link 
                                                    to={`/assistants/${assistantId}/integrations/${integration.id}`}
                                                    className="integration">
                                                    <div className="block">
                                                        <div className="text">
                                                            <h3>{integration.vendor}</h3>
                                                            <p>{integration.name}</p>
                                                        </div>
                                                        {vendorLogoMap[integration.vendor] && <img src={vendorLogoMap[integration.vendor]} />}
                                                        {!vendorLogoMap[integration.vendor] && <h3>{integration.vendor.toUpperCase()}</h3>}
                                                    </div>
                                                </Link>
                                            )
                                        })}
        
                                    </div>
                                </>

                            }

                        </>
                    )

                })}

            </div>

        </div>

    );

}