import { useEffect, useRef, useState } from 'react';
import PageTitle from '../components/PageTitle';
import './AssistantsPage.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/inputs/Button';

import { useAuth } from '../contexts/AuthContext';
import { useNotifications } from '../contexts/NotificationContext';
import api from '../common/api';
import Notice from '../components/Notice';
import FileInput from '../components/inputs/FileInput';
import AreYouSure from '../components/AreYouSure';
import TextInput from '../components/inputs/TextInput';


export default function AssistantsPage() {

    const [loading, setLoading] = useState(true);
    const [assistants, setAssistants] = useState([]);
    const { user } = useAuth();
    const [maxAssistants, setMaxAssistants] = useState(0);

    const { addNotification } = useNotifications();

    const searchInputRef = useRef(null);
    const navigate = useNavigate();

    // are you sure
    const [areYouSureShow, setAreYouSureShow] = useState(false);
    const [areYouSureUrl, setAreYouSureUrl] = useState(null);
    const [areYouSureId, setAreYouSureId] = useState(null);
    const [areYouSureAction, setAreYouSureAction] = useState(null);

    // admin
    const [assistantTransferError, setAssistantTransferError] = useState(null);
    const assistantTransferEmailRef = useRef(null);
    const handleAssistantTransfer = (e) => {

        e.preventDefault();

        const assistantTransferEmail = assistantTransferEmailRef.current.value;
        api.post(`/assistants/${areYouSureId}/:transfer`, {user_email: assistantTransferEmail}).then(() => {
            setAreYouSureShow(false);
            setLoading(true);
            api.get("/assistants")
                .then((response) => {
                    setLoading(false);
                    const newAssistants = [...response.data.data.assistants];
                    setAssistants(newAssistants);
                });
        }).catch(error => {
            if (error.response.status === 404) {
                setAssistantTransferError("This user email address does not exist.");
            }
        });

    }

    const searchAssistants = (_, query) => {

        if (!query) {
            setAssistants([...assistants].map(instance => ({...instance, hide: false})));
            return;
        }

        const queryRegex = new RegExp(query.toLowerCase().replace(' ', ''));
        setAssistants([...assistants].map(assistant => ({
            ...assistant,
            hide: !queryRegex.test((assistant.name + (assistant.description ? assistant.description : '') + ((assistant.remote_name ? assistant.remote_name : ''))).toLowerCase().replace(' ', ''))
        })));

    }
 
    const duplicateInstance = (assistantId) => {

        api.post(`/assistants/${assistantId}/:duplicate`).then(response => {
            const newAssistant = response.data.data.assistants;
            setAssistants([...assistants, {id: newAssistant.id, name: newAssistant.name, description: newAssistant.description}]);
        })

    }

    const location = useLocation();

    // focus search with '/'
    useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === '/' && !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)) {
            e.preventDefault();
            searchInputRef.current?.focus();
          }
    
          if (e.key === 'n' && e.altKey) {
            e.preventDefault();
            e.stopPropagation();
            navigate('/assistants/new');
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
        
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [navigate]);

    useEffect(() => {

        setLoading(true);
        setAssistants([]);
        api.get("/assistants")
            .then((response) => {
                setLoading(false);
                const newAssistants = [...response.data.data.assistants];
                setAssistants(newAssistants);
            })
            .catch(() => {
                setLoading(false);
            });

    }, [location.pathname]);

    useEffect(() => {
        if (user.num_assistants) {
            setMaxAssistants(user.num_assistants);
        }
    }, [user]);

    const instanceType = location.pathname.substring(1);

    return (
        <div 
            id="assistants"
            className={instanceType}
            >

        <PageTitle
            title={instanceType && instanceType.charAt(0).toUpperCase() + instanceType.substring(1).toLowerCase()}
            stats={user && assistants && [
                {"used": assistants.length, "available": (maxAssistants ? maxAssistants : 1), "type": instanceType},
            ]}
            />

            <div className="container">

                <div 
                    id="assistants-container"
                    >

                    {assistants.length > 0 &&
                        <div id="assistants-search">
                            <TextInput 
                                id="assistants-search-input"
                                placeholder={`Search assistant`}
                                innerRef={searchInputRef}
                                inputCallback={searchAssistants}
                                customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}
                                noWhiteBackground
                                />
                            <p><span><span>/</span> to search</span><span><span>ALT + N</span> to create</span></p>
                        </div>
                    }

                    {assistants.length > 0 && assistants.every(assistant => assistant.hide) && 
                        <p id="no-assistants">No assistants that meet your search criteria.</p>
                    }
                    
                    {assistants.map((assistant, key) => {

                        if (assistant.hide) return;

                        const embedLink = `
                            <script crossorigin defer type="module" data-qbud data-host="api.qbud.ai" data-assistant-id="${assistant.id}"src="https://app.qbud.ai/cdn/qbud.js"></script>
                            <link rel="stylesheet" crossorigin href="https://app.qbud.ai/cdn/qbud.css">
                        `.replace(/^\s+|\n\s+/gm, '\n').trim();
                        const demoLink = `https://qbud.ai/demo?id=${assistant.id}`;

                        return (
                            <div
                                key={key} 
                                className="assistant block"
                                >

                                <h2>{assistant.name}</h2>
                                <p>{assistant.description}</p>

                                <div className="assistant-options">
                                    <input 
                                        id={`assistant-options-${key}`}
                                        type="checkbox" 
                                    />
                                    <label
                                        className="hover-effect"
                                        htmlFor={`assistant-options-${key}`}
                                        >
                                        <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z"/></svg>
                                    </label>
                                    <ul>
                                        
                                        <li>
                                            <span 
                                                className="option"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(embedLink);
                                                    addNotification("Embed link copied to clipboard.");
                                                }}
                                                >Copy embed link</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z"/></svg>
                                        </li>

                                        {["admin", "partner"].includes(user.role) &&
                                            <li>
                                                <span 
                                                    className="option"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(demoLink);
                                                        addNotification("Demo link copied to clipboard.");
                                                    }}
                                                    >Copy demo link</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z"/></svg>
                                            </li>
                                        }    

                                        {maxAssistants > assistants.length &&
                                            <li>
                                                <span 
                                                        className="option"
                                                        onClick={() => duplicateInstance(assistant.id)}
                                                    >Duplicate</span>
                                                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m6 19v2c0 .621.52 1 1 1h2v-1.5h-1.5v-1.5zm7.5 3h-3.5v-1.5h3.5zm4.5 0h-3.5v-1.5h3.5zm4-3h-1.5v1.5h-1.5v1.5h2c.478 0 1-.379 1-1zm-1.5-1v-3.363h1.5v3.363zm0-4.363v-3.637h1.5v3.637zm-13-3.637v3.637h-1.5v-3.637zm11.5-4v1.5h1.5v1.5h1.5v-2c0-.478-.379-1-1-1zm-10 0h-2c-.62 0-1 .519-1 1v2h1.5v-1.5h1.5zm4.5 1.5h-3.5v-1.5h3.5zm3-1.5v-2.5h-13v13h2.5v-1.863h1.5v3.363h-4.5c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v4.5h-3.5v-1.5z" fill-rule="nonzero"/></svg>
                                            </li>
                                        }
                                        <li>
                                            <span 
                                                className="option"
                                                onClick={() => {
                                                    setAreYouSureShow(true);
                                                    setAreYouSureUrl(`/assistants/${assistant.id}/:archive`);
                                                    setAreYouSureId(assistant.id);
                                                    setAreYouSureAction("archive");
                                                }}
                                            >Archive</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 24h-20l-2-12h24l-2 12zm-17.226-14l2.667-8h9.117l2.667 8h2.107l-3.332-10h-12l-3.333 10h2.107zm3.248-5l-.333 1h8.621l-.333-1h-7.955zm-.667 2l-.333 1h9.955l-.333-1h-9.289zm10.29 3l-.333-1h-10.624l-.333 1h11.29zm-8.956-7l-.333 1h7.288l-.334-1h-6.621z"/></svg>
                                        </li>

                                        {["admin", "partner"].includes(user.role) &&
                                            <li>
                                                <span 
                                                    className="option"
                                                    onClick={() => {
                                                        setAreYouSureShow(true);
                                                        setAreYouSureId(assistant.id);
                                                        setAreYouSureAction("transfer");
                                                    }}
                                                    >Transfer ownership</span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm2.085 14h-9v2h9v3l5-4-5-4v3zm-4-6v-3l-5 4 5 4v-3h9v-2h-9z"/></svg>
                                            </li>
                                        }                                        

                                    </ul>
                                </div>
                                
                                <div className="buttons">
                                    <Button 
                                        to={`/assistants/${assistant.id}`}
                                        text="Edit"
                                        icon={<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fill-rule="nonzero"/></svg>} 
                                    />
                                    <Button 
                                        to={`/assistants/${assistant.id}/chats`}
                                        text="Chat"
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 1h-24v16.981h4v5.019l7-5.019h13z"/></svg>} 
                                        cta
                                    />
                                </div>
                                

                            </div>
                        )
                    })}

                    {!loading && assistants.length == 0 && maxAssistants > assistants.length &&
                        
                        <Notice
                            id="no-assistants"
                            title="Notification"
                            message={`You don't have any assistants yet. Create one now.`}
                            href={instanceType !== "files" ? `${location.pathname}/new` : null}
                            linkText="Create"
                            >
                        </Notice>

                    }

                    {loading && assistants.length === 0 &&
                        <div id="assistant-placeholder">
                            <div className="loader"></div>
                        </div>
                    }

                    {!loading && maxAssistants > assistants.length &&

                        <div id="new-assistant-wrapper">
                            <Button 
                                to="/assistants/new"
                                text="Create assistant"
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>}
                                cta
                                />
                        </div>

                    }

                </div>  

            </div>
            
            {(["delete", "archive"].includes(areYouSureAction) || (areYouSureAction === "transfer" && ["partners", "admin"].includes(user.role))) &&

                <AreYouSure 
                    isOpen={areYouSureShow}
                    setIsOpen={setAreYouSureShow}
                    url={areYouSureUrl}
                    children={["delete", "archive"].includes(areYouSureAction) ?
                        <>
                            <h3>Are you sure?</h3>
                            <p>You are about to {areYouSureAction} this assistant. Please note that this cannot be undone and {areYouSureAction}d assistants cannot be recovered.</p>
                        </>
                        :
                        <>
                            <h3>Ownership transfer.</h3>
                            <p>Transfer the ownership of this object to a different user.</p>
                            <form
                                onSubmit={handleAssistantTransfer}
                                >
                                <TextInput 
                                    innerRef={assistantTransferEmailRef}
                                    placeholder="Email address of new owner"
                                    type="email"
                                    error={assistantTransferError}
                                    />

                                <Button 
                                    text="Transfer ownership"
                                    submit
                                    center
                                    cta
                                    />
                                
                            </form>
                        </>
                    }
                    verySure={areYouSureAction === "delete"}
                    successCallback={() => { 
                        addNotification(`Successfully ${areYouSureAction}d object.`); 
                        const newAssistants = [...assistants].filter(instance => instance.id !== areYouSureId);
                        setAssistants(newAssistants);
                    }}
                    noButtons={areYouSureAction === "transfer"}
                />

            }

        </div>
        
    )

}