import { useRef, useState } from "react";
import './ImageInput.scss';
import api from "../../common/api";

export default function ImageInput({id, maxFileSize = 1 * 1024 * 1024, allowedExtensions = ['.jpg', '.jpeg', '.png'], onChange, defaultImageUrl, uploadUrl}) {

    const [dragging, setDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (!files.length) return;
        processImage(files[0]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    }

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = Array.from(event.dataTransfer.files);
        if (!files.length) return;
        processImage(files[0]);
    };

    const uploadImage = (file) => {

        const formData = new FormData();
        formData.append('files', file);
    
        api.post(uploadUrl, formData, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}})
            .then(() => {
                if (onChange) onChange();
            })
            .catch(() => {})
            .finally(() => setLoading(false));

    }

    const scaleImage = (file, targetSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = targetSize;
                    canvas.height = targetSize;
    
                    let sourceX = 0;
                    let sourceY = 0;
                    let sourceWidth = img.width;
                    let sourceHeight = img.height;
    
                    if (img.width <= targetSize && img.height <= targetSize) {
                        // Image is smaller, scale it up
                        const scaleFactor = Math.max(targetSize / img.width, targetSize / img.height);
                        sourceWidth = img.width;
                        sourceHeight = img.height;
                        const scaledWidth = img.width * scaleFactor;
                        const scaledHeight = img.height * scaleFactor;
                        sourceX = (targetSize - scaledWidth) / 2;
                        sourceY = (targetSize - scaledHeight) / 2;
                        ctx.drawImage(img, sourceX, sourceY, scaledWidth, scaledHeight);
                    } else {
                        // Image is larger, crop it
                        if (img.width > img.height) {
                            sourceWidth = img.height;
                            sourceX = (img.width - img.height) / 2;
                        } else {
                            sourceHeight = img.width;
                            sourceY = (img.height - img.width) / 2;
                        }
                        ctx.drawImage(
                            img,
                            sourceX,
                            sourceY,
                            sourceWidth,
                            sourceHeight,
                            0,
                            0,
                            targetSize,
                            targetSize
                        );
                    }
    
                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, file.type);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    }

    const processImage = async (file) => {
        setLoading(true);
        if (file.size > maxFileSize) return;
        const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) return;
        const scaledImage = await scaleImage(file, 128);
        setSelectedFile(URL.createObjectURL(scaledImage));
        const scaledFile = new File([scaledImage], file.name, { type: file.type });
        uploadImage(scaledFile);
    };

    const renderFileUrl = selectedFile || defaultImageUrl;

    return (

        <div 
            className={
                "image-input" + 
                (dragging ? " dragging" : "") +
                (renderFileUrl ? " not-empty" : "") +
                (loading ? " loading" : "")
            }
            >

            <input
                id={id}
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept={allowedExtensions.join(',')}
            />

                <label 
                    htmlFor={id}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >

                {!renderFileUrl &&
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm2 14h-4v-1h4v1zm0 1v1h-4v-1h4zm-4-6h-4l6-6 6 6h-4v3h-4v-3z"/></svg>
                        <span>Click to select or drag here</span>    
                    </>
                }

                    {renderFileUrl && 
                        <img src={renderFileUrl} />
                    }

                {loading &&
                    <div className="image-input-loader"></div>
                }

            </label>


        </div>

    )

}