import { useEffect, useState } from "react";

import './BigRadio.scss';

export default function BigRadio({name, choices, defaultValueControlled, onChange, svgImgSrc}) {

   const [value, setValue] = useState(null);

   useEffect(() => {
      
      if (defaultValueControlled) {
         setValue(defaultValueControlled);
      }

   }, [defaultValueControlled]);

   useEffect(() => {
      if (onChange) onChange(value);
   }, [value]);

   return (

      <div 
         className={
            "big-radio" + (
               ` flex-${choices.length}`
            )
         }
         >

         {choices && choices.map(choice => {

            return (
               <div
                  className={
                     (choice.value === value ? "selected" : "") +
                     (choice.comingSoon ? "coming-soon" : "")
                  }
                  onClick={choice.comingSoon ? null : () => setValue(choice.value)}
                  key={choice.value}
                  >
                  {!svgImgSrc && choice.imgSrc && 
                     <img src={choice.imgSrc} />
                  }
                  {svgImgSrc && choice.imgSrc}
                  <p>{choice.title}</p>
                  {choice.text && <p>{choice.text}</p>}
                  {choice.comingSoon && <p className="coming-soon">Coming soon</p>}
               </div>
            )

         })}

         <input name={name} value={value} type="hidden" />

      </div>

   );

}