import { useEffect, useRef, useState } from "react";
import TextInput from "./TextInput";

import './Select.scss';

export function SingleSelect(props) {

    // configuration
    const [choices, setChoices] = useState([]);
    const [showChoices, setShowChoices] = useState([]);
    
    // state
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");
    const [showValue, setShowValue] = useState("");
    const selectRef = useRef(null);

    const handleFocus = () => {

        if (focused || props.readOnly) return;

        const handleClickOutsideSelectRef = (e) => {
            if (selectRef.current !== null && !selectRef.current.contains(e.target)) {
                setFocused(false);
                window.removeEventListener("click", handleClickOutsideSelectRef);
            }
        }

        window.addEventListener("click", handleClickOutsideSelectRef);

        if (props.onFocus) props.onFocus();
        setFocused(true);

    }

    const selectValue = (newValue, newShowValue) => {
        setValue(newValue);
        setShowValue(newShowValue);
        setFocused(false);
        if (props.changeCallback) props.changeCallback(newValue);
    }

    const deselectValue = () => {
        setValue("");
        setShowValue("");
        if (props.changeCallback) props.changeCallback(value);
    }

    const searchValues = (value) => {

        if (!/[^\s]/.test(value)) {
            setShowChoices([...choices]);
            return;
        }

        const query = value.toLowerCase().replaceAll(" ", "");
        let newShowChoices = [...choices]
        newShowChoices = newShowChoices.filter((choice) => {
            return choice.showValue.toLowerCase().replaceAll(" ", "").includes(query);
        });
        setShowChoices(newShowChoices);

    }

    useEffect(() => {
        if (props.choices) {
            let sortedChoices = [...props.choices];
            sortedChoices.sort((a, b) => {
                if (!a.category || b.category || a.category === b.category) return 0;
                if (a.category > b.category) return 1;
                return -1;
            });
            sortedChoices = sortedChoices.map((choice) => ({...choice, show: true}));
            setChoices(sortedChoices);
            setShowChoices(sortedChoices);
        };
    }, [props.searchable, props.choices]);

    useEffect(() => {
        if (props.choices && !value) {
            for (const choice of props.choices) {
                if (choice.value === props.defaultValueControlled) {
                    setValue(choice.value);
                    setShowValue(choice.showValue);
                }
            }
        }
    }, [props.defaultValueControlled, props.choices]);

    return (
        <div 
            id={props.id}
            ref={selectRef}
            className={
                "select" +
                (focused ? " focused" : "") +
                (props.readOnly ? " readonly" : "") + 
                (props.className ? " " + props.className : "") + 
                (props.search ? " search" : "")
            }
            style={props.zIndex ? {zIndex: props.zIndex} : null}
            >

            <TextInput 
                className="select-search"
                defaultValueControlled={showValue}
                placeholder={props.placeholder}
                error={props.error}
                noWhiteBackground={props.noWhiteBackground}
                tabIndex="-1"
                readOnly
                />

            <div 
                className="select-focus-cover"
                onClick={handleFocus}
                ></div>

            <input 
                value={value}
                className="select-hidden-value"
                type="hidden"
                name={props.name}
                ref={props.innerRef}
                />

            <svg
                onClick={() => { setFocused(!focused); }} 
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>

            <div className="choices">

                {props.search &&
                    <TextInput 
                        placeholder="Type to search"
                        inputCallback={(_, newValue) => {
                            searchValues(newValue);
                        }}
                        maxLength={30}
                        onFocus={() => setFocused(true)}
                        />
                }
                
                <ul>
                    {showChoices.map((item, key) => {
                        return (
                            <>

                                {(key === 0 && item.category || item.category && choices[key - 1].category !== item.category) &&
                                    <span className="category">{item.category}</span>
                                }

                                <li
                                    className={item.value === value ? "selected" : ""}
                                    key={item.value}
                                    onClick={() => {
                                        if (item.value !== value) {
                                            selectValue(item.value, item.showValue);
                                        } else {
                                            deselectValue();
                                        }
                                    }}
                                    >
                                    {item.icon &&
                                        <span
                                            className="icon"
                                            >
                                            {item.icon}
                                        </span>
                                    }
                                    {item.showValue}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                                </li>
                            </>
                        );
                    })}

                    {!showChoices.length &&
                        <li className="disabled">No options available</li>
                    }

                </ul>

            </div>

        </div>
    );

}