import { useEffect, useRef, useState } from 'react';
import PageTitle from '../components/PageTitle';
import './IntegrationPage.scss';
import api from '../common/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/inputs/Button';
import { useNotifications } from '../contexts/NotificationContext';
import AreYouSure from '../components/AreYouSure';

const vendorLogoMap = {
    "Sendcloud": require("../assets/sendcloud-logo.png"),
    "DHL": require("../assets/dhl-logo.png"),
    "HubSpot": require("../assets/hubspot-logo.png"),
    "WooCommerce": require("../assets/woocommerce-logo.png"),
    // "Shopify": require("../assets/shopify-logo.png"),
};

const redirectHost = process.env.NODE_ENV === 'production' ? "https://api.qbud.ai" : "http://localhost:5000";
const vendorInfo = {
    Sendcloud: {
        description: "Sendcloud is a service for generating shipping labels for many shipping partners.",
        href: "https://sendcloud.com",
    },
    DHL: {
        description: "DHL is a shipping company that operates worldwide.",
        href: "https://dhl.com",
    },
    HubSpot: {
        description: "HubSpot offers tools for managing sales and marketing processes.",
        href: "https://hubspot.com",
    },
    Zenchef: {
        description: "Zenchef is a reservation platform for restaurants.",
        href: "https://zenchef.com",
    },
    WooCommerce: {
        description: "WooCommerce is an open-source WordPress plugin for building webshops.",
        href: "https://woocommerce.com/",
    },
    Shopify: {
        description: "Shopify is a platform for building and hosting webshops.",
        href: "https://shopify.com",
    },
    Formitable: {
        description: "Formitable is a reservation platform for restaurants.",
        href: "https://formitable.com",
    },
}

function IntegrationCreds({assistantId, integrationId, type, allowPublicUrl, publicKey, publicUrl, vendor, setAssistantIntegration}) {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [areYouSureOpen, setAreYouSureOpen] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);

    const navigate = useNavigate();

    const publicUrlFieldRef = useRef();

    useEffect(() => {
        setAllowEdit([null, undefined].includes(publicKey) && [null, undefined].includes(publicUrl))
    }, [publicKey, publicUrl])

    const { addNotification } = useNotifications();

    const OAuthUrl = {
        "HubSpot": `https://app-eu1.hubspot.com/oauth/authorize?client_id=957a3fd8-5fbd-43bb-a12a-02ebb38eb556&redirect_uri=${redirectHost}/api/v1/integrations/hubspot&scope=tickets%20crm.objects.contacts.write%20e-commerce%20oauth%20crm.objects.companies.write%20crm.objects.deals.write`,
        "Shopify": `${redirectHost}/api/v1/integrations/shopify`
    }

    const checkEnableSubmit = () => {

        let enableSubmit = true;
        const formData = new FormData(formRef.current);
        for (const field of formData.keys()) {
            if (field === "integration_id") continue;
            const value = formData.get(field);
            if (!value || /^•+$/.test(field)) {
                enableSubmit = false;
                break;
            }
        }
        
        setSubmitDisabled(!enableSubmit);

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const formData = new FormData(formRef.current);
        const body = {};
        for (const field of formData.keys()) {
            body[field] = formData.get(field);
        }

        setLoading(true);

        const apiAction = !allowEdit ? api.patch : api.post;
        const apiUrl =  `/assistants/${assistantId}/integrations${(!allowEdit ? '/' + integrationId : '')}`;
        apiAction(apiUrl, body).then(() => {
            navigate(`/assistants/${assistantId}?tab=integrations`);
            addNotification(`Updated integration for ${vendor}.`);
        }).catch(() => {}).finally(() => setLoading(false));

    }

    const startOAuth = () => {

        const url = OAuthUrl[vendor];
        if (vendor === "HubSpot") {
            api.post("/integrations/hubspot", {assistant_id: assistantId}).then(() => {
                window.location.href = url;
            }).catch(() => addNotification("OAuth not available."));
        } else if (vendor === "Shopify") {
            window.location.href = `${url}?public_url=${publicUrlFieldRef.current.value}&assistant_id=${assistantId}`;
        } else {
            window.location.href = url;
        }
    
    }

    const publicUrlPlaceholder = vendor === "Shopify" ? "example.myshopify.com" : "qbud-webshop.ai";
    const publicUrlField = <>
        
        {allowPublicUrl == 1 &&
            <TextInput
                id="integration-creds-public-key"
                name="public_url"
                placeholder="Store URL"
                autoComplete="off"
                example={publicUrlPlaceholder}
                inputCallback={checkEnableSubmit}
                defaultValueControlled={publicUrl}
                readOnly={!allowEdit}
                innerRef={publicUrlFieldRef}
                />
        }

    </>;

    let fields = <></>;

    if (type === "Basic") {

        const publicKeyExample = vendor === "WooCommerce" ? "ck_**********************" : `Issued by ${vendor}`;
        const privateKeyExample = vendor === "WooCommerce" ? "cs_**********************" : `Issued by ${vendor}`;

        fields = 
            <> 
                {publicUrlField}

                <TextInput
                    id="integration-creds-public-key"
                    name="public_key"
                    placeholder="Public key / Client ID"
                    autoComplete="off"
                    example={publicKeyExample}
                    inputCallback={checkEnableSubmit}
                    defaultValueControlled={publicKey}
                    readOnly={!allowEdit}
                    />

                <TextInput 
                    id="integration-creds-private-key"
                    name="private_key"
                    placeholder="Private key / Client secret"
                    type="text"
                    autoComplete="off"
                    example={privateKeyExample}
                    inputCallback={checkEnableSubmit}
                    readOnly={!allowEdit}
                    />
            </>
        ;

    } else if (type === "ApiKey") {

        const placeholder = ["Zenchef", "Formitable"].includes(vendor) ? "Restaurant ID" : "API key";

        fields = 
            <>
                <TextInput 
                    id="integration-creds-private-key"
                    name="private_key"
                    placeholder={placeholder}
                    type="text"
                    autoComplete="off"
                    example={!integrationId || allowEdit ? `Issued by ${vendor}` : "•••••••••••"}
                    inputCallback={checkEnableSubmit}
                    readOnly={!allowEdit}
                    />
            </>
        ;

    } else if (type === "OAuth") {

        fields = 
            <>
                
                {publicUrlField}

                <Button
                    text={!allowEdit ? "Re-authorize qBud" : "Authorize qBud"}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>}
                    onClick={startOAuth}
                    disabled={submitDisabled && allowEdit}
                    cta
                    />

            </>

    } else if (type === "None") {

        fields = publicUrlField;

    }

    return (

        <>

            <form 
                id="integration-creds"
                autoComplete="off"
                onSubmit={handleSubmit}
                ref={formRef}
                >

                {fields}

                <input type="hidden" name="integration_id" value={integrationId} />

                {!allowEdit &&
                    <p id="integration-added">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                        qBud has been authorized for this app. Only update credentials if those issued by your vendor have changed.
                    </p>
                }

                {["ApiKey", "Basic", "None"].includes(type) &&
                
                    <div className="buttons">

                        {!allowEdit &&
                            <Button
                                text="Deactivate"
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.008 21.092l-3.392 1.959c-3.384 1.954-7.713.794-9.668-2.592-1.953-3.385-.793-7.713 2.591-9.667l3.393-1.959 1.495 2.589-3.393 1.96c-1.95 1.125-2.622 3.631-1.495 5.581 1.126 1.953 3.631 2.625 5.582 1.495l3.392-1.956 1.495 2.59zm-3.214-17.553l-1.959 3.392 2.589 1.497 1.959-3.393c1.126-1.95 3.631-2.622 5.581-1.495 1.952 1.127 2.624 3.629 1.499 5.582l-1.96 3.392 2.589 1.494 1.96-3.391c1.952-3.387.792-7.714-2.59-9.667-3.387-1.955-7.716-.795-9.668 2.589zm7.398 20.217l-1.724.244-.523-3.688 1.722-.243.525 3.687zm5.808-7.284l-.249 1.724-3.688-.527.247-1.722 3.69.525zm-5.733 3.013l1.229-1.23 2.638 2.636-1.231 1.23-2.636-2.636z"/></svg>}
                                onClick={() => setAreYouSureOpen(true)}
                                warning
                                />
                        }

                        <Button
                            text="Store"
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-5l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z"/></svg>}
                            disabled={loading || submitDisabled || !allowEdit}
                            submit
                            cta
                            />

                    </div>
                }


            </form>

            <AreYouSure
                children={
                    <>
                        <h2>Are you sure?</h2>
                        <p>Deactivating this integration will cause connected Feeds and/or Flows to not have access to required data.</p>
                    </>
                }
                isOpen={areYouSureOpen}
                setIsOpen={setAreYouSureOpen}
                url={`/assistants/${assistantId}/integrations/${integrationId}`}
                deleteUrl={true}
                successCallback={() => setAssistantIntegration({})}
                verySure={true}
                />

        </>

    )

}

export default function IntegrationPage() {
    
    const { assistant_id, integration_id } = useParams();

    const [integration, setIntegration] = useState({});
    const [assistantIntegration, setAssistantIntegration] = useState({});

    useEffect(() => {

        api.get(`/integrations/${integration_id}`).then(response => {
            setIntegration(response.data.data.integration);
        });

        api.get(`/assistants/${assistant_id}/integrations?integration_id=${integration_id}`).then(response => {
            const newAssistantIntegrations = response.data.data.assistant_integrations;
            if (newAssistantIntegrations && newAssistantIntegrations.length) setAssistantIntegration(newAssistantIntegrations[0]);
        })

    }, []);
    

    return (

        <div 
            id="integration"
            >

            <PageTitle 
                title={integration.name}
                breadcrumb={[
                    {href: `/assistants`, text: "Assistants"},
                    {href: `/assistants/${assistant_id}`, text: "Edit"},
                    {href: `/assistants/${assistant_id}?tab=integrations`, text: 'Integrations'}
                 ]}
                />

            <div className="container">

                <div
                    id="integration-settings" 
                    className="block">

                    <h2>{integration.name}</h2>
                    <p>{integration.description}</p>

                    <IntegrationCreds 
                        assistantId={assistant_id}
                        integrationId={integration_id}
                        publicKey={assistantIntegration.public_key}
                        publicUrl={assistantIntegration.public_url}
                        type={integration.auth_method}
                        vendor={integration.vendor}
                        allowPublicUrl={integration.allow_public_url}
                        setAssistantIntegration={setAssistantIntegration}
                        />

                </div>

                <div 
                    id="integration-vendor"
                    className="block">
                    {vendorLogoMap[integration.vendor] && <img src={integration ? vendorLogoMap[integration.vendor] : ""} />}
                    {integration.vendor && !vendorLogoMap[integration.vendor] && <h3>{integration.vendor && integration.vendor.toUpperCase()}</h3>}
                    <h2>Vendor Info</h2>
                    <p>{integration.vendor && vendorInfo[integration.vendor].description}</p>
                    <Link 
                        target="_blank"
                        to={integration.vendor && vendorInfo[integration.vendor].href}>View vendor<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg></Link>
                </div>
                
            </div>

        </div>

    );

}