import { useEffect, useRef, useState } from 'react';
import Button from '../components/inputs/Button';
import TextInput from '../components/inputs/TextInput';
import BigCheckBox from '../components/inputs/BigCheckbox';
import './LoginPage.scss';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Logo } from '../components/Nav';
import api from '../common/api';
import { useAuth } from '../contexts/AuthContext';


function PostSignupForm() {

    const [loading, setLoading] = useState(false);

    // errors
    const [companyNameError, setCompanyNameError] = useState(null);
    const [errors, setErrors] = useState({});

    const formRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const companyNameRef = useRef(null);

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();

        const errors = {}
        for (const input of [firstNameRef.current, lastNameRef.current, companyNameRef.current]) {
            if (input.value.length === 0) {
                errors[input.getAttribute("name")] = "This value is missing.";
            }
        }
        
        setErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setLoading(true);
        const body = {};
        const formData = new FormData(formRef.current);
        for (const field of formData.keys()) {
            body[field] = formData.get(field);
        }

        api.patch("/user", body).then(() => {
            navigate('/');
        }).catch(() => {
            setCompanyNameError("We couldn't reach the server. Please try again later.");
        }).finally(() => {
            setLoading(false);
        })

    }
    
    return (

        <>

            <p>We need the following details from you.</p>

            <form
                ref={formRef}
                onSubmit={handleSubmit}
                >

                <div className="two-inputs">

                    <TextInput 
                        type="text"
                        placeholder="First name"
                        example="Your first name"
                        name="first_name"
                        innerRef={firstNameRef}
                        readOnly={loading}
                        error={errors.first_name}
                        maxLength={40}
                        validate
                        />

                    <TextInput 
                        type="text"
                        placeholder="Last name"
                        example="Your last name"
                        name="last_name"
                        innerRef={lastNameRef}
                        readOnly={loading}
                        error={errors.last_name}
                        maxLength={40}
                        validate
                        />

                </div>

                <TextInput 
                    type="text"
                    placeholder="Company name"
                    example="Your company name"
                    name="company_name"
                    innerRef={companyNameRef}
                    readOnly={loading}
                    error={companyNameError || errors.company_name}
                    maxLength={40}
                    validate
                    />

                <Button 
                    text="Save"
                    loading={loading}
                    center
                    cta
                    submit
                    />

            </form>

        </>

    )

}


function PasswordForm() {

    const passwordRef = useRef(null);
    const [passwordError, setPasswordError] = useState(null);
    const [loading, setLoading]  = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [search, setSearch] = useSearchParams();

    const handleSubmit = (e) => {

        e.preventDefault();
        setLoading(true);

        const body = {password: passwordRef.current.value, token: search.get("token"), email: search.get("email")};
        api.post("/user/:setpassword", body).then(() => {
            setSubmitted(true);
        }).catch(error => {
            if (error.response.status === 400) {
                setPasswordError('Enter a password that satisfies the requirements.');
            } else if (error.response.status === 401) {
                setPasswordError('Your reset link has expired.');
            } else if (error.response.status === 404) {
                setPasswordError('Your reset link is invalid.');
            } else {
                setPasswordError('We couldn\'t reach the server. Please try again later.');
            }
        }).finally(() => {
            setLoading(false);
        })

    }

    return (
        <>

            {!submitted &&
                
                <>

                    <p>Enter your new password.</p>

                    <form
                        onSubmit={handleSubmit}
                        >
        
                        <TextInput 
                            type="password"
                            placeholder="Password"
                            example="•••••••••"
                            innerRef={passwordRef}
                            error={passwordError}
                            readOnly={loading}
                            onClick={() => { setPasswordError(null) }}
                            validate
                            newPassword
                            />
        
                        <Button 
                            text="Save"
                            loading={loading}
                            center
                            cta
                            submit
                            />
        
                    </form>

                </>

            }

            {submitted &&
                <p>Your new password has been set up. <Link to="/login">Log in here</Link>.</p>
            }

        </>
    )
    
}


function SignupForm() {

    const emailRef = useRef(null);
    const [emailError, setEmailError] = useState(null);
    const [loading, setLoading]  = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [agreeToConditions, setAgreeToConditions] = useState(false);

    const [signUpStartTime, setSignUpStartTime] = useState(null);

    useEffect(() => {
        setSignUpStartTime(new Date());
    }, []);

    const [params, setParams] = useSearchParams();

    const handleSubmit = (e) => {

        e.preventDefault();
        if (!agreeToConditions) return;

        emailRef.current.blur();

        setTimeout(() => {

            if (!emailRef.current.classList.contains("validated")) {
                setEmailError('Add an email address.');
                return;
            }
            setLoading(true);
    
            const body = {
                email: emailRef.current.value,
                submission_time: new Date() - signUpStartTime
            };

            const referralUserId = params.get("ref");
            if (referralUserId) {
                body.referral_user_id = referralUserId;
            }

            api.post("/users", body).then(() => {
                setSubmitted(true);
            }).catch(error => {
                if (error.response.status === 409) {
                    setEmailError('This email address is not available.');
                } else if (error.response.status === 400) {
                    setEmailError('Please sign up using a correct email address.');
                } else {
                    setEmailError('We cannot reach the server. Please try again later.');
                }
            }).finally(() => {
                setLoading(false);
            })

        }, 200);

    }


    return (
        <>

            {!submitted &&
                
                <>

                    <p>Sign up using your email address.</p>

                    <form
                        onSubmit={handleSubmit}
                        >
        
                        <TextInput 
                            type="email"
                            placeholder="Email address"
                            example="abc@email.nl"
                            innerRef={emailRef}
                            error={emailError}
                            readOnly={loading}
                            onClick={() => { setEmailError(null) }}
                            validate
                            />

                        <BigCheckBox
                            text={
                                <>
                                    By checking thix box, you agree to our <Link to="/qbud_privacy_en.pdf" target="_blank">privacy policy</Link> and <Link to="/qbud_dpa_en.pdf" target="_blank">data processing agreement</Link> (DPA).
                                </>
                            }
                            onClick={() => {
                                setAgreeToConditions(!agreeToConditions);
                            }}
                            />
        
                        <Button 
                            text="Sign up"
                            loading={loading}
                            disabled={!agreeToConditions}
                            center
                            cta
                            submit
                            />
        
                    </form>

                </>

            }

            {submitted &&
                <p>We have received your sign up request. Please validate your email address by clicking the link you'll receive from us.</p>
            }

        </>
    )
    
}

export default function SignupPage() {

    const [search, setSearch] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const {user} = useAuth();

    const newSignup = location.search === "" || location.search.startsWith("?ref=");
    const postSignup = location.search === "?stage=post";
    
    useEffect(() => {
        if (search.get("stage")) {
            if (search.get("stage") !== "post" || Object.keys(user).length === 0) {
                navigate('/signup');
                return;
            }

        }
        if (search.get("token") && !search.get("email") || !search.get("token") && search.get("email")) navigate('/signup');
    }, [navigate]);

    return (
        <div id="login">

            <div 
                id="login-wrapper"
                className="block"
                >

                <Logo />

                {location.pathname === "/signup" && newSignup &&
                    <div id="login-switch">
                        <Link to="/login">Log in</Link>
                        <span>Sign up</span>
                    </div>
                }

                <h1>Welcome.</h1>
                
                {newSignup && <SignupForm />}
                {(!newSignup && !postSignup) && <PasswordForm />}
                {postSignup && <PostSignupForm />}
            
            </div>

        </div>
    );

}