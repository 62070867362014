import { useEffect, useRef, useState } from "react";

import './TextInput.scss';
import InputVariables from "./InputVariables";

const InputOrTextarea = (props) => {

    return (
        <>
            {props.multiline ? 
                (<textarea 
                    id={props.id}
                    rows={props.rows ? props.rows : "1"}
                    name={props.name}
                    className={props.className}
                    ref={props.innerRef} 
                    placeholder={props.example}
                    onInput={props.onInput}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onClick={props.onClick}
                    onKeyDown={props.onKeyDown}
                    value={props.value}
                    autoComplete={props.autoComplete}
                    tabIndex={props.tabIndex}
                    />) : 
                (<input 
                    id={props.id}
                    name={props.name}
                    type={props.type ? props.type : "text"}
                    className={props.className}
                    ref={props.innerRef}
                    placeholder={props.example}
                    onInput={props.onInput}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onClick={props.onClick}
                    onKeyDown={props.onKeyDown}
                    value={props.value}
                    autoComplete={props.autoComplete}
                    tabIndex={props.tabIndex}
                    />)}
        </>
    );

}

export default function TextInput(props) {

    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    const [validated, setValidated] = useState(false);
    const [passwordPeek, setPasswordPeek] = useState(false); 
    const [infoShow, setInfoShow] = useState(false);
    const [showInputVariables, setShowInputVariables] = useState(false);

    useEffect(() => {
        if (props.error) {
            setError(props.error);
        }
    }, [props.error]);

    useEffect(() => {
        if (props.defaultValueControlled || props.readOnly) {
            setValue(props.defaultValueControlled !== " " ? String(props.defaultValueControlled) : "");
            validate(props.defaultValueControlled !== " " ? String(props.defaultValueControlled) : "");
            setError(null);
        }
    }, [props.defaultValueControlled]);

    const wrapperRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            const wrapper = wrapperRef.current;
            if (wrapper) {
                const inputs = wrapper.getElementsByTagName(props.rows || props.multiline ? "textarea" : "input");
                if (inputs.length && inputs[0].matches(':autofill')) {
                    inputs[0].parentElement.parentElement.click();
                    if (props.validate) setValidated(true);
                    setTimeout(() => {
                        inputs[0].classList.add("not-empty");
                    }, 100);
                }
            }
        }, 300);
    }, []);

    const passwordRequirementsDiv = useRef(null);

    const validate = (validateValue) => {

        if (!props.validate) return;

        let newError = null;
        let validated = true;

        if (props.type === "email") {
            if (validateValue && !/^[^\s]+@[^\s]+\.[^\s]+$/.test(validateValue)) {
                newError = "Dit is geen geldig e-mailadres.";
            }
        } else if (props.newPassword) {
            const requirements = passwordRequirementsDiv.current.getElementsByTagName("span");
            const requirementsRegex = [/^.{12,50}$/, /[a-z]/, /[A-Z]/, /\d/];
            for (let i = 0; i < 4; i++) {
                if (requirementsRegex[i].test(validateValue)) {
                    requirements[i].classList.add('check');
                } else {
                    requirements[i].classList.remove('check');
                    validated = false;
                }
            }
        }

        if (props.validateRegex && validateValue) {
            if (!props.validateRegex.test(validateValue)) {
                newError = "Dit is geen geldige invoer.";
            }
        }

        if (newError && props.validateError) {
            newError = props.validateError;
        }

        setError(newError);
        setValidated(validateValue.length > 0 && newError === null && validated);

    }

    const handleBlur = (e) => {

        if (!value) {
            setValue("");
            return;
        };

        setValue(value.trim())
        validate(value.trim());
        if (props.blurCallback) props.blurCallback(e, value);
        if (props.inputVariables) {
            if (!wrapperRef.current.contains(e.relatedTarget)) {
                setShowInputVariables(false);
            }
        };

    }

    const handleInput = (e) => {

        let newValue = String(e.target.value);

        if (props.numbersOnly) {
            
            newValue = newValue.replace(/[^\d]/, '');

            if (parseInt(newValue) > props.max) {
                setError(`Max of ${props.max} allowed.`)
                return;
            } else if (parseInt(newValue) < props.min) {
                setError(`Min of ${props.min} allowed.`);
                return;
            }

        }

        if (props.forceLowerCase) {
            newValue = newValue.toLowerCase();
        }

        if (!props.maxLength || newValue.length <= props.maxLength) {
            if (props.newPassword) {
                validate(newValue);
            }
            if (props.inputCallback) props.inputCallback(e, newValue);
            setValue(newValue);
        }

        setError(null);

    }

    const showInfo = () => {

        const hideInfo = () => {
            setInfoShow(false);
            window.removeEventListener("click", hideInfo);
        }

        if (infoShow) {
            hideInfo();
            return;
        }

        setInfoShow(true);
        setTimeout(() => { window.addEventListener("click", hideInfo)}, 0);

    }

    return (
        <div
            ref={wrapperRef}
            className={
                "text-input" + 
                (props.innerClassName ? " " + props.innerClassName : "")
            }>

            {props.info &&

                <>
                    <svg
                        onClick={showInfo}
                        className="text-input-info-icon" 
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
                    <p 
                        className={"text-input-info" + (infoShow ? " show" : "")}
                        >
                        <strong>{props.placeholder}</strong>
                        {props.info}
                    </p>
                </>

            }

            {(props.customIcon && !props.info) &&
                <span class="text-input-custom-icon">
                    {props.customIcon}
                </span>
            }

            <InputOrTextarea 
                id={props.id} 
                name={props.name}
                className={
                    (value && value.length > 0 ? "not-empty" : "") +
                    (error ? " error" : "") +
                    (validated ? " validated" : "") + 
                    (props.readOnly ? " readonly" : "") +
                    (props.noWhiteBackground ? " no-white-bg" : "") +
                    (props.info || props.customIcon ? " info" : "") +
                    " " + props.className
                }
                innerRef={props.innerRef}
                placeholder={props.example}
                type={props.type ? (passwordPeek ? "text" : props.type) : "text"}
                onInput={handleInput}
                onFocus={() => setValidated(false) }
                onBlur={handleBlur}
                onClick={(e) => {
                    if (props.onClick) props.onClick();
                }}
                onKeyDown={props.onKeyDown}
                value={value === null ? "" : value}
                multiline={props.multiline}
                rows={props.rows}
                example={props.example}
                autoComplete={props.autoComplete}
                inputMode={props.inputMode}
                tabIndex={props.tabIndex || (props.readOnly ? -1 : null)}
                />

            <p 
                className={"text-input-error" + (error !== null ? " show" : "")}>
                {error}
            </p>

            {props.placeholder && 
                <span
                    className="text-input-placeholder"
                    >
                    {props.placeholder}
                </span>
            }

            {props.validate &&
                <span
                    className={
                        "text-input-check" +
                        (validated ? " approved" : "")
                    }
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                </span>
            }

            {props.maxLength &&
                <span
                    className={
                        "text-input-maxlength" +
                        ((((value ? value.length : 0) / props.maxLength) > 0.9) ? " almost-full" : "")
                    }
                    
                    >
                        {(value ? value.length : 0)} / {props.maxLength}
                </span>
            }
            
            {props.newPassword &&
                <div
                    ref={passwordRequirementsDiv} 
                    className="text-input-password-requirements"
                    >
                    <span>12-50 tekens</span>
                    <span>1 kleine letter</span>
                    <span>1 hoofdletter</span>
                    <span>1 cijfer</span>
                </div>
            }

            {props.type === "password" &&
                <span
                    className="text-input-peek"
                    onClick={() => { setPasswordPeek(!passwordPeek)} }
                    >
                    {!passwordPeek && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/></svg>}
                    {passwordPeek && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>}
                </span>
            }

            {props.inputVariables &&
                <span
                    className="text-input-input-variables"
                    onClick={() => setShowInputVariables(!showInputVariables)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 10.826v2.349c-1.562 0-3 1.312-3 2.857 0 2.181 1.281 5.968-6 5.968v-2.002c4.917 0 3.966-1.6 3.966-3.967 0-2.094 1.211-3.5 2.278-4.031-1.067-.531-2.278-1.438-2.278-3.312 0-2.372.94-4.692-3.966-4.686v-2.002c7.285 0 6 4.506 6 6.688 0 1.544 1.438 2.138 3 2.138zm-19-2.138c0-2.182-1.285-6.688 6-6.688v2.002c-4.906-.007-3.966 2.313-3.966 4.686 0 1.875-1.211 2.781-2.278 3.312 1.067.531 2.278 1.938 2.278 4.031 0 2.367-.951 3.967 3.966 3.967v2.002c-7.281 0-6-3.787-6-5.969 0-1.545-1.438-2.857-3-2.857v-2.349c1.562.001 3-.593 3-2.137z"/></svg>
                </span>
            }

            {props.inputVariables &&
                <InputVariables 
                    showInputVariables={showInputVariables}
                    setShowInputVariables={setShowInputVariables}
                    value={value}
                    setValue={setValue}
                    maxLength={props.maxLength}
                    />
            }

        </div>
    )

}