import './FeedPage.scss';
import PageTitle from '../components/PageTitle';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/inputs/Button';
import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router';

import { useNotifications } from '../contexts/NotificationContext';
import api from '../common/api';
import FeedBuilder from '../components/builders/FeedBuilder';
import { SingleSelect } from '../components/inputs/Select';
import { useSearchParams } from 'react-router-dom';



export default function FeedPage(props) {

   // default values
   const [feed, setFeed] = useState({});
   const [testItems, setTestItems] = useState([]);
   const [tab, setTab] = useState(null);
   const [loading, setLoading] = useState(true);

   // panel selections
   const location = useLocation();
   const [searchParams, setSearchParams] = useSearchParams();
   const { assistant_id, feed_id } = useParams();
   useEffect(() => {
      setTab(searchParams.get("tab"));
   }, [location, searchParams]);

   // feed types
   const basicFeedTypes = [{value: "file", showValue: "File"}, {value: "text", showValue: "Text"}];
   const [feedTypes, setFeedTypes] = useState(basicFeedTypes);

   useEffect(() => {
      
      if (feed_id) {

         setLoading(true);
         api.get(`/assistants/${assistant_id}/feeds/${feed_id}`)
            .then(response => {
               setFeed({...response.data.data.feed});
            })
            .catch(error => {
               if (error.response.status === 404) {
                  navigate(`/assistants/${assistant_id}?tab=feeds`)
               }
            });

      }

      api.get(`/assistants/${assistant_id}/integrations`).then(response => {
         const assistantIntegrations = response.data.data.assistant_integrations;
         const newFeedTypes = [...basicFeedTypes];
         for (const integration of assistantIntegrations) {
            if (["Shopify", "WooCommerce"].includes(integration.vendor)) {
               newFeedTypes.push({
                  value: integration.vendor.toLowerCase(),
                  showValue: `${integration.vendor} product feed`
               });
            }
         }
         setFeedTypes(newFeedTypes);
      })

      // make sure selects don't overlap with lower selects
      setTimeout(() => {

         const selects = document.getElementsByClassName("select");
         let zFeed = 9;
         for (const select of selects) {
            select.style.zFeed = zFeed--;
         }

      }, 100);

   }, [location.pathname]);

   // submissions
   const navigate = useNavigate();
   const [submissionLoading, setSubmissionLoading] = useState(false);
   const [errors, setErrors] = useState({});
   const { addNotification } = useNotifications();

   const handleSubmit = (e) => {

      e.preventDefault();
      setErrors({});

      const formData = new FormData(e.target);
      const body  = {}
      const newErrors = {}
      formData.forEach((value, key) => {
         if (["query", "search_key", "search_value", "search_value_title"].includes(key)) return;
         if (!value) {
            newErrors[key] = "Provide this value.";
            return;
         } else if (key === "items") {
            body[key] = JSON.parse(value);
         } else {
            body[key] = value;
         }
      });

      if (Object.keys(newErrors).length) {
         setErrors(newErrors);
         return;
      }

      setSubmissionLoading(true);
      if (feed_id) {
         api.patch(`/assistants/${assistant_id}/feeds/${feed_id}`, body)
            .then(() => {
               addNotification("Feed successfully updated.");
               api.get(`/assistants/${assistant_id}/feeds/${feed_id}`)
                  .then(response => {
                     setFeed({...response.data.data.feed});
                  })
                  .catch(() => {});
            })
            .catch(() => {})
      } else {
         api.post(`/assistants/${assistant_id}/feeds`, body)
            .then(response => {
               addNotification("Feed successfully created.");
               navigate(`/assistants/${assistant_id}/feeds/${response.data.data.feed.id}`);
            })
            .catch(() => {})
      }
      setSubmissionLoading(false);

   }

   const queryRef = useRef(null);
   const sendTestQuery = () => {
      
      setSubmissionLoading(true);
      const query = queryRef.current.value;
      if (!query || !(/[A-za-z]/.test(query))) {
         setErrors({query: "Please fill in a search query."});
         setSubmissionLoading(false);
         return;
      }
      
      api.get(`/assistants/${assistant_id}/feeds/${feed_id}/:search?query=${encodeURIComponent(query)}&n=10`)
         .then(response => {
            if (response.data.data.items) {
               setTestItems([...response.data.data.items]);
               setSubmissionLoading(false);
            }
         })

   }

   return (
      <div 
         id="feed"
         >

         <PageTitle
            title="Basics."
            breadcrumb={[
               {href: `/assistants`, text: "Assistants"},
               {href: `/assistants/${assistant_id}`, text: "Edit"},
               {href: `/assistants/${assistant_id}?tab=feeds`, text: 'Feeds'}
            ]}
            links={[
               {text: "Basics", to: location.pathname, icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 9.185l7 6.514v6.301h-3v-5h-8v5h-3v-6.301l7-6.514zm0-2.732l-9 8.375v9.172h7v-5h4v5h7v-9.172l-9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"/></svg>},
               {text: "Items", inactive: props.new, to: location.pathname + '?tab=items', icon: <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m10.5 17.25c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-3.55c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm1.5-1.7c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-6c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm1.5.75c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero"/></svg>},
               {text: "Test", inactive: props.new, to: location.pathname + '?tab=test', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.759 20.498c-2.342-3.663-5.575-6.958-5.743-11.498h-2.016c.173 5.212 3.512 8.539 5.953 12.356.143.302-.068.644-.377.644h-1.264l-4.734-7h-3.52c.873-1.665 1.85-3.414 1.936-6h-2.01c-.169 4.543-3.421 7.864-5.743 11.498-.165.347-.241.707-.241 1.057 0 1.283 1.023 2.445 2.423 2.445h13.153c1.4 0 2.424-1.162 2.424-2.446 0-.35-.076-.709-.241-1.056zm-4.759-15.498c0 1.105-.896 2-2 2s-2-.895-2-2 .896-2 2-2 2 .895 2 2zm-5-1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5.672-1.5 1.5-1.5 1.5.671 1.5 1.5zm0 3.5c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3-6c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1z"/></svg>}
            ]}
            />

         <div 
            id="feed-container"
            className="container"
            >

            <div id="feed-form-tips">

               <form
                  className="block"
                  onSubmit={handleSubmit}
                  >
                  
                  <div
                     style={{display: tab ? 'none' : ''}}
                     >

                     <h2>Basic info.</h2>
                     <p>Tell your assistant what the feed should be used for. Be descriptive.</p>

                     <div className="two-inputs">

                        <TextInput 
                           id="feed-name"
                           name="name"
                           validate
                           example="Restaurant menu"
                           placeholder="Feed name"
                           maxLength="30"
                           error={errors.name}
                           autoComplete="off"
                           info="This shows up in your list of indices and when creating flows based on this feed."
                           defaultValueControlled={feed.name}
                           />

                        <SingleSelect
                           id="feed-type"
                           name="type"
                           placeholder="Type"
                           choices={feedTypes}
                           defaultValueControlled={feed.type}
                           readOnly={!props.new}
                           />

                     </div>

                     <TextInput 
                        id="feed-description"
                        name="description"
                        validate
                        example="This feed contains food items."
                        placeholder="Feed instruction"
                        maxLength="100"
                        error={errors.description}
                        autoComplete="off"
                        info="This helps the AI understand what it is supposed to do with the value it finds."
                        defaultValueControlled={feed.description}
                        />

                  </div>

                  <div
                     style={{display: tab !== "items" ? 'none' : ''}}
                     >
                     
                     <h2>Feed items.</h2>
                     <p>Specify a search key that is used to compare search queries against. The best matching values and their title are returned.</p>
                     
                     <FeedBuilder 
                        name="items"
                        items={feed.items}
                        type={feed.type}
                        readOnly={["woocommerce", "shopify"].includes(feed.type)}
                        />

                  </div>

                  <div
                     style={{display: tab !== "test" ? 'none' : ''}}
                     >
                     
                     <h2>Test feed.</h2>
                     <p>Enter a query and observe which items are returned. Only the three best matches are shown.</p>
                     
                     <div id="feed-query-wrapper">
                        <TextInput 
                           name="query"
                           innerRef={queryRef}
                           placeholder="Search query"
                           example="Your search query"
                           error={errors.query}
                           onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                 e.preventDefault();
                                 sendTestQuery();
                              }
                           }}
                           />
                        <Button 
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 12l-20 12 5-12-5-12z"/></svg>}
                           onClick={sendTestQuery}
                           loading={submissionLoading}
                           cta iconOnly
                           />
                     </div>

                     <FeedBuilder 
                        name="items"
                        items={testItems}
                        readOnly
                        />

                  </div>

                  {tab !== "test" &&

                     <div className="buttons">

                        <Button 
                           text="Cancel"
                           to={`/assistants/${assistant_id}?tab=feeds`}
                           center
                           />

                        <Button 
                           text={props.new ? "Create" : "Save"}
                           loading={submissionLoading}
                           center
                           cta
                           submit
                           />

                     </div>

                  }

               </form>

            </div>

         </div>

      </div>
   )

}