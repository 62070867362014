import { useEffect, useState } from 'react';
import './FlowBuilder.scss';
import TextInput from '../inputs/TextInput';
import { SingleSelect } from '../inputs/Select';
import api from '../../common/api';
import Button from '../inputs/Button';


const FLOW_CHOICES = [
   {value: "SEND_EMAIL", category: 'Output', showValue: "Send an email", icon: <><span>+2</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 1l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-4.638 6.251c.99-.576 1.864-1.296 2.638-2.17v2.053c-.667.588-1.391 1.096-2.175 1.527l-.463-1.41zm-4.883 3.06c-.978.125-2.355.188-3.479.188v-1.455c1.032 0 2.327-.056 3.229-.169l.25 1.436zm4.184-1.086l-.055.023c-.879.369-1.831.655-2.865.859l-.25-1.438c.982-.197 1.885-.473 2.714-.833l.456 1.389z"/></svg></>},
   {value: "ADD_LABEL", category: 'Output', showValue: "Add label to chat", icon: <><span>Free</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.605 0h-10.605v10.609l13.391 13.391 10.609-10.604-13.395-13.396zm-4.191 6.414c-.781.781-2.046.781-2.829.001-.781-.783-.781-2.048 0-2.829.782-.782 2.048-.781 2.829-.001.782.782.781 2.047 0 2.829z"/></svg></>},
   {value: "SHOW_LINK", category: 'Show field', showValue: "Show hyperlink", icon: <><span>Free</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.848 15.932c-.399.781-.732 1.599-1.003 2.447-1.14-2.552-3.109-4.676-5.912-6.267l-1.463 2.656-2.47-5.881 6.33-1.126-1.433 2.601c2.606 1.472 4.593 3.349 5.951 5.57zm9.219-3.819l1.463 2.655 2.47-5.881-6.33-1.126 1.433 2.601c-5.339 3.017-8.103 7.72-8.103 13.638h2c0-5.144 2.405-9.241 7.067-11.887zm-8.025-12.113l-4.042 5h3v7.762c.352.409.684.831.991 1.269.312-.443.65-.87 1.009-1.286v-7.745h3l-3.958-5z"/></svg></>},
   {value: "SHOW_GOOGLE_MAPS", category: 'Show field', showValue: "Show Google Maps location", icon: <><span>Free</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z"/></svg></>},
   {value: "SHOW_FILE", category: 'Show field', showValue: "Show a qBud File", icon: <><span className="integration">Free</span><svg className="integration" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.568.075c2.202 1.174 5.938 4.883 7.432 6.881-1.286-.9-4.044-1.657-6.091-1.179.222-1.468-.185-4.534-1.341-5.702zm-.824 7.925s1.522-8-3.335-8h-8.409v24h20v-13c0-3.419-5.247-3.745-8.256-3z"/></svg></>},
   {value: "SEARCH_FEED_TEXT", category: 'Feed', showValue: "Construct response from qBud Feed", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.812 20.171c-.791.523-1.738.829-2.757.829-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.019-.306 1.966-.829 2.757l2.829 2.829-1.414 1.414-2.829-2.829zm-9.083-1.171c-3.528 0-5.729-2.65-5.729-5.5 0-2.702 1.952-4.945 4.521-5.408.212-3.951 3.474-7.092 7.479-7.092 4.006 0 7.267 3.141 7.479 7.092 2.57.463 4.521 2.706 4.521 5.408 0 2.881-2.261 5.5-5.62 5.5.433-.909.675-1.927.675-3 0-3.863-3.137-7-7-7-3.864 0-7 3.137-7 7 0 1.073.242 2.091.674 3m6.326-6c1.655 0 3 1.345 3 3s-1.345 3-3 3c-1.656 0-3-1.345-3-3s1.344-3 3-3"/></svg></>},
   {value: "SEARCH_FEED_LINK", category: 'Feed', showValue: "Retrieve link from qBud Feed", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.812 20.171c-.791.523-1.738.829-2.757.829-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.019-.306 1.966-.829 2.757l2.829 2.829-1.414 1.414-2.829-2.829zm-9.083-1.171c-3.528 0-5.729-2.65-5.729-5.5 0-2.702 1.952-4.945 4.521-5.408.212-3.951 3.474-7.092 7.479-7.092 4.006 0 7.267 3.141 7.479 7.092 2.57.463 4.521 2.706 4.521 5.408 0 2.881-2.261 5.5-5.62 5.5.433-.909.675-1.927.675-3 0-3.863-3.137-7-7-7-3.864 0-7 3.137-7 7 0 1.073.242 2.091.674 3m6.326-6c1.655 0 3 1.345 3 3s-1.345 3-3 3c-1.656 0-3-1.345-3-3s1.344-3 3-3"/></svg></>},
   {value: "SEARCH_FEED_LINK_AND_TEXT", category: 'Feed', showValue: "Construct response + link from qBud Feed", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.812 20.171c-.791.523-1.738.829-2.757.829-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.019-.306 1.966-.829 2.757l2.829 2.829-1.414 1.414-2.829-2.829zm-9.083-1.171c-3.528 0-5.729-2.65-5.729-5.5 0-2.702 1.952-4.945 4.521-5.408.212-3.951 3.474-7.092 7.479-7.092 4.006 0 7.267 3.141 7.479 7.092 2.57.463 4.521 2.706 4.521 5.408 0 2.881-2.261 5.5-5.62 5.5.433-.909.675-1.927.675-3 0-3.863-3.137-7-7-7-3.864 0-7 3.137-7 7 0 1.073.242 2.091.674 3m6.326-6c1.655 0 3 1.345 3 3s-1.345 3-3 3c-1.656 0-3-1.345-3-3s1.344-3 3-3"/></svg></>},
   {value: "SENDCLOUD_SHIPPING_STATUS", category: 'Integrations - Shipping', showValue: "Retrieve Sendcloud shipping status", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M18.5 12c-3.036 0-5.5 2.463-5.5 5.5s2.464 5.5 5.5 5.5c3.035 0 5.5-2.463 5.5-5.5s-2.465-5.5-5.5-5.5zm-6.634 9h-11.866v-20h22v9.866c-.62-.328-1.292-.572-2-.716v-7.15h-7v6h-4v-6h-7v16h9.15c.144.708.388 1.38.716 2zm6.019-2.94l-1.259-1.182-.962.989 2.24 2.133 3.765-3.834-.979-.97-2.805 2.864zm-8.885-1.06h-5v-2h5v2z"/></svg></>},
   {value: "DHL_SHIPPING_STATUS", category: 'Integrations - Shipping', showValue: "Retrieve DHL shipping status", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M18.5 12c-3.036 0-5.5 2.463-5.5 5.5s2.464 5.5 5.5 5.5c3.035 0 5.5-2.463 5.5-5.5s-2.465-5.5-5.5-5.5zm-6.634 9h-11.866v-20h22v9.866c-.62-.328-1.292-.572-2-.716v-7.15h-7v6h-4v-6h-7v16h9.15c.144.708.388 1.38.716 2zm6.019-2.94l-1.259-1.182-.962.989 2.24 2.133 3.765-3.834-.979-.97-2.805 2.864zm-8.885-1.06h-5v-2h5v2z"/></svg></>},
   {value: "HUBSPOT_ADD_CONTACT", category: 'Integrations - Sales', showValue: "Add contact in HubSpot", icon: <><span className="integration">Free</span><svg className="integration" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.324 11.387c-.547-1.272-2.073-2.124-3.41-1.901-1.337.222-1.979 1.433-1.432 2.706.578 1.343 2.234 2.202 3.598 1.863 1.209-.299 1.766-1.456 1.244-2.668zm-1.683 1.584l.12.267-.253.052-.113-.254c-.262.047-.561.041-.836-.031l-.076-.451c.227.035.509.067.725.023l.145-.047c.258-.126.179-.449-.204-.525-.284-.063-1.038-.017-1.319-.642-.158-.353-.041-.722.414-.891l-.125-.278.252-.052.12.266c.185-.031.401-.042.655-.02l.102.446c-.197-.025-.41-.047-.596-.009l-.07.018c-.359.095-.244.436.075.519.527.122 1.156.161 1.404.712.199.445-.034.75-.42.897zm7.198-7.971c-6.829 4.423-14.376-.351-19.839 4.92l4.161 9.08c5.854-4.962 13.835-.264 19.839-4.92l-4.161-9.08zm-12.843 10.475c-.815-.511-2.002-.838-2.875-.762l-1.189-2.597c.567-.608 1.021-1.684 1.169-2.655.185-.059.371-.117.566-.165 3.358-.833 7.543.079 11.852-.99.312-.077.609-.174.908-.269.678.475 1.511.809 2.302.923l1.316 2.871c-.532.673-.912 1.483-1.105 2.327-3.865 1.048-8.549-.094-12.944 1.317z"/></svg></>},
   {value: "ZENCHEF_CHECK_AVAILABILITY", category: 'Integrations - Sales', showValue: "Check availability in Zenchef", icon: <><span className="integration">Free</span><svg className="integration" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg></>},
   {value: "FORMITABLE_CHECK_AVAILABILITY", category: 'Integrations - Sales', showValue: "Check availability in Formitable", icon: <><span className="integration">Free</span><svg className="integration" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg></>},
   {value: "WOOCOMMERCE_GET_ORDER", category: 'Integrations - Sales', showValue: "Find order in WooCommerce", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg></>},
   {value: "SHOPIFY_GET_ORDER", category: 'Integrations - Sales', showValue: "Find order in Shopify", icon: <><span className="integration">Free</span><svg className="integration" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg></>},
];

function FlowFields(props) {

   const [fields, setFields] = useState([]);

   useEffect(() => {
      if (props.action && props.flowFieldMap) setFields(props.flowFieldMap[props.action]);
   }, [props.action, props.flowFieldMap]);

   if (!props.action) return;

   return (
      <>
         {props.flowFieldMap[props.action].map((field, key) => {

            const elementKey = props.action + field.name;

            if (["feed", "file"].includes(field.type)) {

               let choices;
               if (field.type === "feed") {
                  choices = props.feeds.map((feed) => {
                     return {value: feed.id, showValue: feed.name, icon: <svg className="integration" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.812 20.171c-.791.523-1.738.829-2.757.829-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.019-.306 1.966-.829 2.757l2.829 2.829-1.414 1.414-2.829-2.829zm-9.083-1.171c-3.528 0-5.729-2.65-5.729-5.5 0-2.702 1.952-4.945 4.521-5.408.212-3.951 3.474-7.092 7.479-7.092 4.006 0 7.267 3.141 7.479 7.092 2.57.463 4.521 2.706 4.521 5.408 0 2.881-2.261 5.5-5.62 5.5.433-.909.675-1.927.675-3 0-3.863-3.137-7-7-7-3.864 0-7 3.137-7 7 0 1.073.242 2.091.674 3m6.326-6c1.655 0 3 1.345 3 3s-1.345 3-3 3c-1.656 0-3-1.345-3-3s1.344-3 3-3"/></svg>}
                  })
               } else {
                  choices = props.files.map((file) => {
                     return {value: file.id, showValue: file.remote_name.split("/", 2)[1], icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.568.075c2.202 1.174 5.938 4.883 7.432 6.881-1.286-.9-4.044-1.657-6.091-1.179.222-1.468-.185-4.534-1.341-5.702zm-.824 7.925s1.522-8-3.335-8h-8.409v24h20v-13c0-3.419-5.247-3.745-8.256-3z"/></svg>}
                  })
               }

               return (
                  <SingleSelect
                     key={elementKey + (props.action ? props.action : "")}
                     choices={choices}
                     defaultValueControlled={props.parameters[field.name]}
                     changeCallback={(value) => { props.updateFlow(props.index, ["then", props.innerIndex, "parameters", field.name], value) }}
                     placeholder={field.label}
                     zIndex={props.index - key}
                     />
               )

            }

            return (
               <TextInput 
                  key={elementKey}
                  placeholder={field.label}
                  name={`flow-builder-${field.name}-${key}`}
                  defaultValueControlled={props.parameters[field.name]}
                  blurCallback={(e, value) => { props.updateFlow(props.index, ["then", props.innerIndex, "parameters", field.name], value) }}
                  maxLength={field.maxLength || 200}
                  example={field.example}
                  autoComplete="off"
                  inputVariables={field.variables}
                  />
            );
         })}
      </>
   )

}


function FlowStore({flows, setFlows, feeds, show, setShow}) {

   const flowTemplates = {
      "Complaint": {
         if: "A message is a complaint.",
         then: [{action: "ADD_LABEL", parameters: {label: "Complaint"}}, {action: "SEND_EMAIL", parameters: {to: "", body: "User complaint encountered: {{USER_MESSAGE}}"}}],
         description: "Marks the chat with a label and sends an email about the complaint."
      },
      "Sales Opportunity": {
         if: "The user is interested in services, product offerings, or reaching out to sales.",
         then: [{action: "ADD_LABEL", parameters: {label: "Sales"}}, {action: "SEND_EMAIL", parameters: {to: "", body: "Sales opportunity encountered {{USER_MESSAGE}}"}}],
         description: "Marks the chat with a label and sends an email about the opportunity."
      }
   }

   const productFeed = feeds.find(feed => ["shopify", "woocommerce"].includes(feed.type));
   if (productFeed) {
      flowTemplates["Product Feed"] = {
         if: "The user enquires about products or recommendations",
         then: [{action: "SEARCH_FEED_LINK_AND_TEXT", parameters: {feed: productFeed.id}}],
         description: "Recommend a product from one of your product feeds."
      }
   }

   const addFlow = (flowTemplateName) => {

      const newFlows = [...flows];
      newFlows.push({if: flowTemplates[flowTemplateName].if, then: flowTemplates[flowTemplateName].then});
      setFlows(newFlows);
      setShow(false);

   }

   if (!show) return;

   return (

      <div id="flow-store" className="block">

         <h2>Flow store.</h2>
         <p>Select a template below.</p>

         <ul>
            {Object.keys(flowTemplates).map((flowTemplateName) => {

               const flowTemplate = flowTemplates[flowTemplateName];

               return (
                  <li
                     key={flowTemplateName}
                     onClick={() => addFlow(flowTemplateName)}
                     >
                     <h3>{flowTemplateName}</h3>
                     <p>{flowTemplate.description}</p>

                     <div className="tags">
                        {flowTemplate.then.map((action, key) => {
                           const actionPretty = action.action.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                           return (<span key={key}>{actionPretty}</span>)
                        })}
                     </div>

                  </li>
               );

            })}
         </ul>

         <div 
            className="flow-store-close"
            onClick={() => setShow(false)}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
         </div>

      </div>

   )

}


export default function FlowBuilder(props) {

   const MAX_FLOWS = 10;

   const [canCreateNewFlow, setCanCreateNewFlow] = useState(true);
   const [errors, setErrors] = useState(null);

   const [flows, setFlows] = useState([]);
   const [flowChoices, setFlowChoices] = useState([]);
   const [flowFieldMap, setFlowFieldMap] = useState({});
   const [flowCreditsPerMessage, setFlowCreditsPerMessage] = useState({});
   const [flowStoreShow, setFlowStoreShow] = useState(false);

   const [feeds, setFeeds] = useState([]);
   const [files, setFiles] = useState([]);

   useEffect(() => {

      if (!props.assistantId) return;

      Promise.all([
         api.get(`/assistants/${props.assistantId}/feeds`).catch(error => { if (error.response?.status === 404) return { data: { data: { feeds: [] } } }  }),
         api.get(`/assistants/${props.assistantId}/flows`).catch(error => { if (error.response?.status === 404) return { data: { data: { flows: [] } } }  }),
         api.get(`/assistants/${props.assistantId}/files`).catch(error => { if (error.response?.status === 404) return { data: { data: { assistant_files: [] } } } }),
      ]).then(([feedsResponse, flowsResponse, filesResponse]) => {

         const newFeeds = feedsResponse.data.data.feeds;
         setFeeds(newFeeds);

         const newFiles = filesResponse.data.data.assistant_files;
         setFiles(newFiles);

         const availableFlows = flowsResponse.data.data.flows;

         console.log(availableFlows);
            
         let newFlowChoices = [];
         for (const flow of FLOW_CHOICES) {
            if (availableFlows.some(availableFlow => flow.value === availableFlow.name)) {
               newFlowChoices.push(flow);
            }
         }

         if (newFeeds.length === 0) {
            newFlowChoices = newFlowChoices.filter(choice => !choice.value.startsWith("SEARCH_"))
         }

         if (newFiles.length === 0) {
            newFlowChoices = newFlowChoices.filter(choice => !choice.value.endsWith("_FILE"));
         }

         const newFlowCreditsPerMessage = {};
         const newFlowFieldMap = {};
         for (const flow of availableFlows) {
            newFlowFieldMap[flow.name] = JSON.parse(flow.fields);
            if (flow.credits) newFlowCreditsPerMessage[flow.name] = {
               numCredits: flow.credits,
               name: flow.name.charAt(0) + flow.name.substring(1).replace(/_/g, ' ').toLowerCase(),
               onlyIfRuns: true
            };
         }

         setFlowFieldMap(newFlowFieldMap);
         setFlowChoices(newFlowChoices);
         setFlowCreditsPerMessage(newFlowCreditsPerMessage);

         const newCreditsLineItems = [{numCredits: 2, name: "Basic message"}];
         for (const flow of flows) {
            const action = flow.then.action;
            if (Object.keys(newFlowCreditsPerMessage).includes(action)) {
               newCreditsLineItems.push(newFlowCreditsPerMessage[action]);
            }
         }
         props.setCreditsLineItems(newCreditsLineItems);

      })

   }, [props.assistantId]);

   const updateFlow = (index, tree, newValue) => {
      
      const newFlows = [...flows];
      let node = newFlows[index];
      for (let i = 0; i < tree.length; i++) {
         if (i == tree.length - 1) {
            node[tree[i]] = newValue;
         }
         node = node[tree[i]];
      }
      setCanCreateNewFlow(allValuesFilled(newFlows));
      setFlows(newFlows);

      if (tree.length === 1 && tree[0] === "then") {
         const newCreditsLineItems = [{numCredits: 2, name: "Basic message"}];
         for (const flow of newFlows) {
            const action = flow.then.action;
            if (Object.keys(flowCreditsPerMessage).includes(action)) {
               newCreditsLineItems.push(flowCreditsPerMessage[action]);
            }
         }
         props.setCreditsLineItems(newCreditsLineItems);
      }

   }
   
   const addActionToFlow = (index) => {
      
      const newFlows = [...flows];
      const newThen = newFlows[index].then;
      newThen.push({"action": ""});
      newFlows[index].then = newThen;
      setCanCreateNewFlow(false);
      setFlows(newFlows);

   }

   const removeActionFromFlow = (index) => {
      const newFlows = [...flows];
      const newThen = [...newFlows[index].then];
      newThen.pop();
      newFlows[index].then = newThen;
      setCanCreateNewFlow(allValuesFilled(newFlows));
      setFlows(newFlows);
   }

   const deleteFlow = (index) => {
      
      let newFlows = [...flows];
      newFlows.splice(index, 1);
      setFlows(newFlows);
      setCanCreateNewFlow(allValuesFilled(newFlows));

      const newCreditsLineItems = [{numCredits: 2, name: "Basic message"}];
      for (const flow of newFlows) {
         const action = flow.then.action;
         if (Object.keys(flowCreditsPerMessage).includes(action)) {
            newCreditsLineItems.push(flowCreditsPerMessage[action]);
         }
      }
      props.setCreditsLineItems(newCreditsLineItems);

   }

   const allValuesFilled = (obj) => {
      return Object.values(obj).every(val => 
        val != null && 
        (typeof val !== 'string' || val.trim() !== '') &&
        (typeof val !== 'object' || 
          (Array.isArray(val) ? val.length && val.every(allValuesFilled) : allValuesFilled(val)))
      ) || Array.isArray(obj) && obj.length === 0;
    }

   useEffect(() => {
      if (props.flows) {
         
         const newFlows = JSON.parse(props.flows);
         setFlows(newFlows);
         setCanCreateNewFlow(allValuesFilled(newFlows));

      }
   }, [props.flows, props.feeds]);

   useEffect(() => {
      if (props.errors) setErrors(props.errors);
   }, [props.errors]);

   return (

      <>

         <div 
            id="flow-builder"
            onClick={() => setErrors(null)}
            >

            {Object.keys(flowFieldMap).length > 0 && flows.map((flow, outerKey) => {

               return (
                  <div 
                     className="flow"
                     key={outerKey + "if"}
                     >

                     <TextInput
                        placeholder="IF"
                        className="if-input"
                        defaultValueControlled={flow.if}
                        maxLength={100}
                        example="If a message is a complaint"
                        name={`flow-builder-if-${outerKey}`}
                        autoComplete="off"
                        inputCallback={(e, value) => {
                           updateFlow(outerKey, ["if"], value);
                        }}
                        />

                     {flow.then.map((then, innerKey) => {

                        return (
                           <>
                              <SingleSelect
                                 placeholder="THEN"
                                 choices={flowChoices}
                                 className="then"
                                 name={`flow-builder-then-${outerKey}-${innerKey}`}
                                 defaultValueControlled={then.action}
                                 changeCallback={(value) => {
                                    updateFlow(outerKey, ["then", innerKey], {
                                       action: value,
                                       parameters: flowFieldMap[value].map(parameter => parameter.name).reduce((o, v) => (o[v] = "", o), {})
                                    });
                                 }}
                                 zIndex={100 - outerKey * 2}
                                 search
                                 />

                              <FlowFields 
                                 action={then.action}
                                 flowFieldMap={flowFieldMap}
                                 parameters={then.parameters}
                                 updateFlow={updateFlow}
                                 index={outerKey}
                                 innerIndex={innerKey}
                                 feeds={feeds}
                                 files={files}
                                 />
                           </>
                        )
                        
                     })}

                     <div className="flow-buttons">

                        <div 
                           className="delete-flow"
                           >
                           <span
                              onClick={() => { deleteFlow(outerKey)} }
                              >
                              Remove Flow
                           </span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
                        </div>

                        <div 
                           className={"add-flow remove action" +
                              (flow.then.length <= 1 ? " disabled" : "")
                           }
                           onClick={() => removeActionFromFlow(outerKey)}
                           >
                           <span>Remove action</span>
                           <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"/></svg>
                        </div>

                        <div className="add-flow add action">
                           <span
                              onClick={() => addActionToFlow(outerKey)}
                              >Add action
                           </span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                        </div>

                     </div>

                  </div>
               );

            })}

            {flows.length === 0 &&
               <p id="no-flow-message">No Flows yet.</p>
            }

            <div id="add-flow-buttons">

               <Button
                  text="Use Flow Store" 
                  disabled={!canCreateNewFlow || flows.length >= MAX_FLOWS}
                  onClick={() => setFlowStoreShow(true)}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 9v-1.098l1.047-4.902h1.905l1.048 4.9v1.098c0 1.067-.933 2.002-2 2.002s-2-.933-2-2zm5 0c0 1.067.934 2 2.001 2s1.999-.833 1.999-1.9v-1.098l-2.996-5.002h-1.943l.939 4.902v1.098zm-10 .068c0 1.067.933 1.932 2 1.932s2-.865 2-1.932v-1.097l.939-4.971h-1.943l-2.996 4.971v1.097zm-4 2.932h22v12h-22v-12zm2 8h18v-6h-18v6zm1-10.932v-1.097l2.887-4.971h-2.014l-4.873 4.971v1.098c0 1.066.933 1.931 2 1.931s2-.865 2-1.932zm15.127-6.068h-2.014l2.887 4.902v1.098c0 1.067.933 2 2 2s2-.865 2-1.932v-1.097l-4.873-4.971zm-.127-3h-14v2h14v-2z"/></svg>                  }
                  />

               <Button
                  text="Add custom"
                  disabled={!canCreateNewFlow || flows.length >= MAX_FLOWS}
                  onClick={() => {
                     setTimeout(() => {
                        if (flows.length >= MAX_FLOWS) return;
                        if (allValuesFilled(flows)) {
                           setFlows([...flows, {
                              "if": "",
                              "then": [{"action": ""}]
                           }]);
                        }
                     }, 100);
                  }}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm5 3c0-2.761-2.238-5-5-5h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14zm-13 12h-2v3h-2v-3h-2v-3h6v3zm-2-13h-2v8h2v-8zm10 5h-6v3h2v8h2v-8h2v-3zm-2-5h-2v3h2v-3z"/></svg>}
                  />

            </div>

            <input type="hidden" name="flows" value={JSON.stringify(flows)} />
            <input id="flow-builder-validated" type="hidden" value={canCreateNewFlow} />

            {errors && <p id="flow-builder-errors">{errors}</p>}

         </div>

         <FlowStore 
            flows={flows}
            setFlows={setFlows}
            feeds={feeds}
            show={flowStoreShow}
            setShow={setFlowStoreShow}
            />

      </>

   );

}