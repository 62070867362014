import { useEffect, useState } from "react";
import TextInput from "./TextInput";

import './ColorInput.scss';

export default function ColorInput({name, defaultValueControlled}) {
   
   const [color, setColor] = useState(defaultValueControlled || "#5e5cdb");

   const updateColor = (colorCode) => {

      if (!colorCode) return;
      colorCode = colorCode.toLowerCase().replaceAll(' ', '');
      if (/^(rgb\((\d{1,3},){2}\d{1,3}\)|#(\d|[a-f]){6})$/.test(colorCode)) {
         setColor(colorCode);
      }

   }

   useEffect(() => {
      if (defaultValueControlled) updateColor(defaultValueControlled);
   }, [defaultValueControlled]);

   return (

      <div className="color-input">

         <TextInput
            name={name}
            placeholder="Widget color"
            example="rgb(x, x, x) or #xxxxxx"
            inputCallback={(_, value) => updateColor(value)}
            defaultValueControlled={defaultValueControlled || "#5e5cdb"}
            maxLength="20"
            />

         <div
            className="color"
            style={{
               backgroundColor: color
            }}
            ></div>

      </div>

   )

}