import React, { useEffect, useState } from 'react';

import './Table.scss';
import TextInput from './inputs/TextInput';


const HighlightedText = ({ text, highlight }) => {

   if (!text || !highlight || !highlight.trim()) {
     return <span>{text}</span>;
   }

   const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
   
   return (
     <span>
       {parts.map((part, index) => 
         part.toLowerCase() === highlight.toLowerCase() ? (
           <span key={index} className="yellow">{part}</span>
         ) : (
           <span key={index}>{part}</span>
         )
       )}
     </span>
   );
 };


const Table = ({columns = [], data = [], className = ""}) => {

   const [sortedData, setSortedData] = useState([]);
   const [shownColumns, setShownColumns] = useState([]);
   const [shownData, setShownData] = useState([]);
   const [currentSort, setCurrentSort] = useState({key: null, type: "none"});
   const [searchValue, setSearchValue] = useState(null);
   const [searchable, setSearchable] = useState(false);
   const [showColumnList, setShowColumnList] = useState(false);
   const [showRowList, setShowRowList] = useState(false);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   
   // pages
   const [page, setPage] = useState(0);
   const [maxPage, setMaxPage] = useState(0);

   const getCellClasses = (column) => {
      return [
         column.align === "right" ? "right" : "",
         column.tag === true ? "tag" : "",
         column.sortable === true ? "sortable" : "",
         column.options === true ? "options" : ""
      ].join(" ").replaceAll(/\s{2,}/g, " ").trim();
   };

   const getCellStyles = (column) => {

      const style = {
         width: column.width ? `${column.width}%` : "auto"
      };
      if (column.minWidth) {
         style.minWidth = column.minWidth;
      }

      return style;

   }

   function sortByKey(array, key, ascending = true) {
      const sortedArray = [...array];
      
      return sortedArray.sort((a, b) => {

         const valueA = a[key];
         const valueB = b[key];
         
         let comparison;
         
         if (typeof valueA === 'string') {
            comparison = valueA.localeCompare(valueB);
         } else if (valueA instanceof Date) {
            comparison = valueA.getTime() - valueB.getTime();
         } else {
            comparison = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
         }
         
         return ascending ? comparison : -comparison;
      });
   }

   const sortShownData = (key) => {
      
      let direction = "ascending";
      if (key === currentSort.key) {
         if (currentSort.direction === "ascending") {
            direction = "descending";
         } else if (currentSort.direction === "descending") {
            direction = "none;"
         }
      }
      
      let newSortedData;
      if (direction === "none") {
         newSortedData = [...data];
      } else {
         newSortedData = sortByKey(data, key, direction === "ascending");
      }

      setCurrentSort({key: key, direction: direction});
      setSortedData(newSortedData);
      filterData(newSortedData);

   }

   const computeMaxPage = (data) => {

      const newMaxPage = Math.max(Math.ceil(data.length / rowsPerPage) - 1, 0);
      if (page > newMaxPage) setPage(newMaxPage);
      return newMaxPage;

   }

   const filterData = (data, filterValue) => {

      const standardizeValue = (str) => {
         if (!str) return "";
         return String(str).toLowerCase();
      }

      // need to be consecutive because of delay in state variables
      if (filterValue === "") {
         setShownData([...data]);
         setMaxPage(computeMaxPage(data));
         return;
      }
      if (!filterValue && !searchValue) {
         setMaxPage(computeMaxPage(data));
         setShownData([...data]);
         return;
      }

      const filteredData = [];
      const query = new RegExp(standardizeValue(filterValue || searchValue));
      for (const row of data) {
         
         let searchTarget = "";
         for (const column of columns) {
            if (column.searchable) {
               searchTarget += standardizeValue(row[column.key]);
            }
         }
         if (query.test(searchTarget)) {
            filteredData.push(row);
         }

      }
      
      setShownData(filteredData);
      setMaxPage(computeMaxPage(filteredData));

   }
   
   useEffect(() => {
      setSortedData([...data]);
      setShownData([...data]);
      setMaxPage(computeMaxPage(data));
      setShownColumns(columns.map(column => column.key));
   }, [data]);

   useEffect(() => {
      setMaxPage(computeMaxPage(shownData));
   }, [rowsPerPage]);

   useEffect(() => {
      setSearchable(columns.some(column => column.searchable));
   }, [columns]);

   return (
      <div className="table block">

         <div className="table-options">
         
            {searchable &&

               <TextInput 
                  example="John Doe"
                  placeholder="Search table"
                  inputCallback={(e, newValue) => {
                     filterData(data, newValue);
                     setSearchValue(newValue);
                  }}
                  customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}
               />
               
            }

            <span
               className={showColumnList ? "show" : ""}
               >
               <svg 
                  onClick={() => {
                     setShowColumnList(!showColumnList);
                     setShowRowList(false);
                  }}
                  clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4 2.999c-.478 0-1 .379-1 1v16c0 .62.519 1 1 1h16c.621 0 1-.52 1-1v-16c0-.478-.379-1-1-1zm.5 16.5v-15h9.5v15zm3.658-11.321c-.137-.124-.299-.179-.458-.179-.358 0-.7.284-.7.705v6.59c0 .422.342.705.7.705.159 0 .321-.055.458-.178 1.089-.982 2.684-2.417 3.576-3.22.17-.153.266-.371.266-.601 0-.229-.096-.448-.265-.601-.893-.803-2.487-2.239-3.577-3.221z" fill-rule="nonzero"/></svg>

               <ul
                  >

                  <li>Shown columns</li>

                  {columns.map(column => {

                     if (!column.header) return;

                     const activeColumn = shownColumns.includes(column.key);

                     return (
                        <li 
                           className={activeColumn ? "active" : ""}
                           key={column.key} 
                           onClick={() => {
                              if (activeColumn) {
                                 setShownColumns(shownColumns.filter(showColumn => showColumn !== column.key));
                              } else {
                                 setShownColumns([...shownColumns, column.key]);
                              }
                           }}>

                           {column.header}

                           <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                           </span>

                        </li>
                     )
                  })}

               </ul>

            </span>

            <span
               className={showRowList ? "show" : ""}
               >
               <svg 
                  onClick={() => {
                     setShowRowList(!showRowList);
                     setShowColumnList(false);
                  }}
                  clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4 2.999c-.478 0-1 .379-1 1v16c0 .62.519 1 1 1h16c.621 0 1-.52 1-1v-16c0-.478-.379-1-1-1zm.5 16.5v-15h9.5v15zm3.658-11.321c-.137-.124-.299-.179-.458-.179-.358 0-.7.284-.7.705v6.59c0 .422.342.705.7.705.159 0 .321-.055.458-.178 1.089-.982 2.684-2.417 3.576-3.22.17-.153.266-.371.266-.601 0-.229-.096-.448-.265-.601-.893-.803-2.487-2.239-3.577-3.221z" fill-rule="nonzero"/></svg>

               <ul
                  >

                  <li>Rows per page</li>

                  {[10, 20, 30, 50, 100].map(numRows => {
                     
                     const active = numRows === rowsPerPage;

                     return (
                        <li 
                           className={active ? "active" : ""}
                           key={numRows} 
                           onClick={() => {
                              setRowsPerPage(numRows);
                           }}>

                           {numRows} rows

                           <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                           </span>

                        </li>
                     )
                  })}

               </ul>

            </span>

         </div>

         <div className="table-wrapper">

            <table className={className}>
               
               <thead>
                  
                  <tr>
                     
                     {columns.map((column, index) => {

                        if (!shownColumns.includes(column.key)) return;
                        
                        return (
                           <th 
                              key={index}
                              className={getCellClasses(column)}
                              style={getCellStyles(column)}
                              onClick={() => sortShownData(column.key)}
                           >
                           
                              {column.header}
                              
                              {column.sortable &&

                                 <span 
                                    className={"sort " + (currentSort.key === column.key ? currentSort.direction : "")}
                                    >
                                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m8.656 5.778c0 .414.336.75.75.75h5.16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-5.16c-.414 0-.75.336-.75.75zm-2.206 4c0 .414.336.75.75.75h9.596c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-9.596c-.414 0-.75.336-.75.75zm-2.45 4c0 .414.336.75.75.75h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75zm-2 4c0 .414.336.75.75.75h18.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.344 17.778c0-.414-.336-.75-.75-.75h-5.16c-.414 0-.75.336-.75.75s.336.75.75.75h5.16c.414 0 .75-.336.75-.75zm2.206-4c0-.414-.336-.75-.75-.75h-9.596c-.414 0-.75.336-.75.75s.336.75.75.75h9.596c.414 0 .75-.336.75-.75zm2.45-4c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75zm2-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>
                                 </span>

                              }

                           </th>
                        )

                     })}

                  </tr>

               </thead>

               <tbody>
                  
                  {shownData.map((row, rowIndex) => {
                     
                     const lowerBound = page * rowsPerPage;
                     const upperBound = lowerBound + rowsPerPage;
                     if (rowIndex < lowerBound || rowIndex >= upperBound) return;

                     return (

                        <tr key={rowIndex}>
                           
                           {columns.map((column, cellIndex) => {
                              
                              if (!shownColumns.includes(column.key)) return;

                              return(

                                 <td 
                                    key={cellIndex}
                                    className={getCellClasses(column) + (!row[column.key] ? " empty" : "")}
                                    style={getCellStyles(column)}
                                 >

                                    {!row[column.key] && "N/A"}

                                    {row[column.key] && 
                                       (column.tag === true || column.options === true ?
                                          <span>{row[column.key]}</span> :  
                                          <HighlightedText 
                                             text={row[column.key] ? row[column.key] : "N/A"} 
                                             highlight={row[column.key] ? searchValue : null} />                                        
                                       )
                                    }

                                 </td>
                              )

                           })}
                           
                        </tr>

                     );

                  })}

                  {!shownData.length &&
                     <tr>
                        <td className="only" colSpan="9999">There is no data that meets the requirements.</td>
                     </tr>
                  }

               </tbody>

            </table>

         </div>

         <div className="pages">

               {page >= 2 &&
                  <>
                     <span onClick={() => setPage(0)}>1</span>
                     {page > 2 && <div className="divider">•••</div>}
                  </>
               }

               {page === maxPage && maxPage > 3 &&
                  <span onClick={() => setPage(maxPage - 2)}>{maxPage - 1}</span>
               }
               
               {Array(3).fill(null).map((_, increment) => {

                  const currentPage = page + increment - 1;
                  if ((currentPage < 1 && page > 1) || (currentPage > maxPage - 1 && page < maxPage - 1) || currentPage < 0 || currentPage > maxPage) return;

                  return (
                     <span className={currentPage === page ? "active" : ""} onClick={() => setPage(currentPage)}>{currentPage + 1}</span>
                  )
               })}

               {page === 0 && maxPage > 2 &&
                  <span onClick={() => setPage(2)}>3</span>
               }

               {maxPage > 3 && page < maxPage - 1 &&
                  <>
                     {page < maxPage - 2 && <div className="divider">•••</div>}
                     <span onClick={() => setPage(maxPage)}>{maxPage + 1}</span>
                  </>
               }

            </div>

      </div>
   );
};

export default Table;