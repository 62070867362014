import { useEffect, useRef, useState } from 'react';
import Button from '../components/inputs/Button';
import TextInput from '../components/inputs/TextInput';
import './LoginPage.scss';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Logo } from '../components/Nav';
import axios from 'axios';
import { useNotifications } from '../contexts/NotificationContext';
import api, {loginApi, logoutApi} from '../common/api';


function RequestForm() {

    const [loading, setLoading]  = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const emailRef = useRef(null);
    const [emailError, setEmailError] = useState(null);

    const handleSubmit = (e) => {

        e.preventDefault();
        emailRef.current.blur();

        setTimeout(() => {

            if (!emailRef.current.classList.contains("validated")) {
                return;
            }

            setLoading(true);

            const body = {email: emailRef.current.value,}
            api.post("/user/:resetpassword", body).then(response => {
                setSubmitted(true);
            }).catch(error => {
                if (error.response.status === 429) {
                    setEmailError('You have already requested a new password. Please check your email and spam folder.');
                } else if (error.response.status === 400) {
                    setEmailError('Please provide a valid email address.');
                } else {
                    setEmailError('We can\'t reach the server right now. Please try again later.');
                }
            }).finally(() => {
                setLoading(false);
            })

        }, 200);

    }

    if (submitted) {

        return (
            <>
                <h1>Reset password.</h1>
                <p>Your reset link has been sent to the provided email address.</p>
            </>
        )

    }

    return (
        <>

            <h1>Reset password.</h1>
            <p>Request a reset link using your email address below.</p>
            
            <form
                onSubmit={handleSubmit}
                >

                <TextInput 
                    type="email"
                    placeholder="Email address"
                    example="abc@email.nl"
                    innerRef={emailRef}
                    error={emailError}
                    readOnly={loading}
                    inputCallback={() => { setEmailError(null) }}
                    forceLowerCase
                    validate
                    />

                <Button 
                    text="Request password"
                    loading={loading}
                    center
                    cta
                    submit
                    />

            </form>

        </>
    )

}


function MfaForm() {

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();

    const [loading, setLoading]  = useState(false);

    const mfaCodeRef = useRef(null);
    const [mfaCodeError, setMfaCodeError] = useState(null);

    const handleSubmit = (e) => {

        if (e) e.preventDefault();
        setTimeout(() => mfaCodeRef.current.blur(), 50);

        setTimeout(() => {

            if (!mfaCodeRef.current.classList.contains("validated")) {
                return;
            }

            setLoading(true);

            const body = {mfa_id: search.get("mfa_id"), mfa_code: mfaCodeRef.current.value}
            loginApi.post("", body).then(response => {
                navigate(search.get('redirect') ? search.get('redirect') : '/');
                addNotification("You're logged in.");
            }).catch(error => {
                if (error.response.status === 429) {
                    setMfaCodeError('You have tried too often. Please request a new code.');
                } else if (error.response.status === 401) {
                    setMfaCodeError('This confirmation code seems invalid. Please try again or request a new one.');
                } else {
                    setMfaCodeError('We can\'t reach the server right now. Please try again later.');
                }
            }).finally(() => {
                setLoading(false);
            })

        }, 200);

    }

    return (
        <>

            <h1>2FA.</h1>
            <p>Please enter the code you received at your email address.</p>
            
            <form
                onSubmit={handleSubmit}
                >

                <TextInput 
                    type="text"
                    className="mfa"
                    placeholder="Confirmation code"
                    example="12345678"
                    minLength={8}
                    maxLength={8}
                    innerRef={mfaCodeRef}
                    error={mfaCodeError}
                    readOnly={loading}
                    inputCallback={(_, value) => { 
                        setMfaCodeError(null);
                        if (value.length === 8) {
                            handleSubmit();
                        }
                    }}
                    inputMode="numeric"
                    numbersOnly
                    validate
                    />

                <input type="hidden" name="mfa_id" />

                <Button 
                    text="Confirm"
                    loading={loading}
                    center
                    cta
                    submit
                    />

            </form>

        </>
    )

}


function LoginForm() {

    const [loading, setLoading]  = useState(false);

    const emailRef = useRef(null);
    const [emailError, setEmailError] = useState(null);
    const passwordRef = useRef(null);
    const [passwordError, setPasswordError] = useState(null);

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    const { addNotification } = useNotifications();

    const handleSubmit = (e) => {

        e.preventDefault();
        setLoading(true);

        let submit = true;
        if (!emailRef.current.classList.contains("validated")) {
            setEmailError('Add an email address.')
            submit = false;
        }
        if (!passwordRef.current.value.length > 0) {
            setPasswordError('Add a password.')
            submit = false;
        }

        if (!submit) {
            setLoading(false);
            return;
        }

        const body = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        loginApi.post("", body).then(response => {
            if (response.data.data.mfa_id) {
                navigate(`/login?mfa_id=${response.data.data.mfa_id}` + (search.get('redirect') ? `&redirect=${search.get('redirect')}` : ''))
            } else {
                navigate(search.get('redirect') ? search.get('redirect') : '/');
                addNotification("You're logged in.");
            }
        }).catch(error => {
            if (error.response.status === 401) {
                setPasswordError('These credentials are unfamiliar to us.');
            } else {
                setPasswordError('We can\'t reach the server right now. Please try again later.');
            }
        }).finally(() => {
            setLoading(false);
        })

    }


    return (
        <>

            <h1>Welcome back.</h1>
            <p>First, log in below.</p>

            <form
                onSubmit={handleSubmit}
                >

                <TextInput 
                    type="email"
                    placeholder="Email address"
                    example="abc@email.nl"
                    innerRef={emailRef}
                    error={emailError}
                    readOnly={loading}
                    inputCallback={() => { setEmailError(null) }}
                    forceLowerCase
                    validate
                    />

                <TextInput 
                    type="password"
                    placeholder="Password"
                    example="•••••••••"
                    innerRef={passwordRef}
                    error={passwordError}
                    readOnly={loading}
                    inputCallback={() => { setPasswordError(null) }}
                    />

                <p id="login-password-reset-link"><Link to="/login?forgot=true">Forgot password</Link>?</p>

                <Button 
                    text="Log in"
                    loading={loading}
                    center
                    cta
                    submit
                    />

            </form>

        </>
    )

}


export default function LoginPage() {

    const { addNotification } = useNotifications();

    const [search, setSearch] = useSearchParams();

    // log out
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/logout') {
            logoutApi.post("").then(() => {
                navigate('/login');
                addNotification('You have been logged out.');
            });
        }
    }, [location.pathname, navigate]);

    const requestPassword = search.get("forgot");
    const mfaCode = search.get("mfa_id");

    return (
        <div id="login">

            <div 
                id="login-wrapper"
                className="block"
                >

                <Logo />

                <div id="login-switch">
                    <span>Log in</span>
                    <Link to="/signup">Sign up</Link>
                </div>

                {!mfaCode &&
                    <>
                        {requestPassword && <RequestForm />}
                        {!requestPassword && <LoginForm />}
                    </>
                }

                {mfaCode && <MfaForm />}
            
            </div>

        </div>
    );

}