import PageTitle from '../components/PageTitle';
import { useEffect, useRef, useState } from 'react';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/inputs/Button';

import { useNotifications } from '../contexts/NotificationContext';
import api from '../common/api';

import './TeamPage.scss';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Notice from '../components/Notice';



function TeamEdit({team, teamUsers, setTeamUsers}) {

    const [userEmail, setUserEmail] = useState(null);
    const [userEmailDefault, setUserEmailDefault] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
 
    const deleteUser = (userId) => {
 
       api.delete(`/teams/${team.id}/users/${userId}`).then(() => {
          addNotification("User successfully deleted.");
          setTeamUsers([...teamUsers.filter((user) => user.id !== userId)]);
       }).catch(() => {
          addNotification("User does not exist.");
       });
 
    }
 
    const addUser = (e) => {
 
       e.preventDefault();
       if (!userEmail || userEmail.length < 5) return;
        
        setLoading(true);
        api.post(`/teams/${team.id}/users`, {email: userEmail}).then(response => {
            addNotification("User added.");
            const newTeamUsers = [...teamUsers];
            newTeamUsers.push(response.data.data.user);
            setTeamUsers(newTeamUsers);
            setUserEmailDefault(" ");
        }).catch(() => {
            addNotification("User does not exist.");
        }).finally(() => setLoading(false));
 
    }
 
    useEffect(() => {
       setUserEmailDefault(null);
    }, [userEmail]);
 
    return (
       <div id="team">
          
          <p>Welcome to <span>{team.name}</span>!</p>
 
          <ul id="team-users">
             
             {teamUsers.map((user) => {
                return (
                   <li className="user block">
                      
                      <div className={"icon" + (user.is_owner ? " owner" : "")}>
                         {user.is_owner == true &&
                            <>
                               <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" viewBox="0 0 24 24" clip-rule="evenodd"><path d="M16.944 9c.228 1.618-.206 3.713-1.633 6.259-1.231 2.196 1.438 2.99 4.169 3.586 2.449.534 2.532 1.685 2.523 3.701l-.003 1.454h-20l-.004-1.477c-.008-2.002.083-3.147 2.523-3.678 2.673-.583 5.491-1.378 4.223-3.586-1.449-2.525-1.896-4.628-1.667-6.259h9.869zm.056-1h-10v-4.011c0-.427-.016-.896-.428-1.085-.34-.157-.572-.506-.572-.904 0-.552.448-1 1-1 .75 0 1.331.842.799 1.597-.268.379-.204.608-.023.874.383.564.936 1.029 1.724 1.029 1.26 0 1.927-1.042 2.065-1.924.048-.307.075-.537-.218-.822-.201-.195-.347-.452-.347-.754 0-.552.448-1 1-1s1 .448 1 1c0 .302-.147.559-.348.754-.293.285-.266.515-.218.822.139.882.805 1.924 2.066 1.924.787 0 1.341-.465 1.723-1.029.181-.266.246-.495-.022-.874-.532-.755.049-1.597.799-1.597.552 0 1 .448 1 1 0 .398-.232.747-.572.904-.413.189-.428.658-.428 1.085v4.011z"/></svg>
                               <span>Owner</span>
                            </>
                         }
                         {!user.is_owner == true &&
                            <>
                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>
                               <span>{user.status !== "invited" ? "Member" : "Invited"}</span>
                            </>
                         }
                      </div>
 
                      <h3>{user.first_name} {user.last_name}</h3>
                      <p>{user.email}</p>
                      
                      {team.is_owner == true && user.is_owner != true &&
                         <svg 
                            onClick={() => deleteUser(user.id)}
                            clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                      }
                         
                   </li>
                )
             })}
 
             {teamUsers.length === 0 &&
                <p>There are no team members yet.</p>
             }
 
          </ul>
 
          {team.is_owner == true &&
          
             <div id="team-add">
 
                <form onSubmit={addUser}>
 
                   <h2>Invite users.</h2>
                   <p>Fill in email addresses of users that you want to share assistants, indices, or files with.</p>
 
                   <TextInput
                      name="name"
                      placeholder="User email"
                      example="johndoe@qbud.ai"
                      defaultValueControlled={userEmailDefault}
                      inputCallback={(e, newValue) => setUserEmail(newValue)}
                      noWhiteBackground
                   />
 
                   <Button 
                      disabled={(!userEmail || userEmail.length < 5)}
                      text="Add user"
                      cta submit
                   />
 
                </form>
 
             </div>
 
          }
 
       </div>
    )
 
}

function TeamCreate() {

    const [teamName, setTeamName] = useState(null);
    const [loading, setLoading] = useState(false);

    const { addNotification } = useNotifications();

    const navigate = useNavigate();
 
    const createTeam = (e) => {
 
       e.preventDefault();
       if (!teamName || teamName.length < 3) return;
       setLoading(true);
       api.post("/teams", {name: teamName}).then(response => {
          const team = response.data.data.team;
          navigate(`/teams/${team.id}`);
          addNotification(`Created team "${teamName}".`);
       }).catch(() => {}).finally(() => setLoading(false));
 
    }
 
    return (
       <form id="team-create" className="block" onSubmit={createTeam}>
          
            <h2>Team name.</h2>
            <p>Provide a recognizable team name. After this, you can add members that share access to your assistants.</p>
    
            <TextInput
                name="name"
                placeholder="Team name"
                example="Team qBud"
                inputCallback={(e, newValue) => setTeamName(newValue)}
                maxLength={50}
                />
    
            <Button 
                disabled={!teamName || teamName.length < 3}
                loading={loading}
                text="Save"
                cta submit
                />
 
       </form>
    )
 
 }

function Teams({teams, setTeams, invitations, setInvitations}) {

    const { user } = useAuth(); 
    const { addNotification } = useNotifications();

    const acceptInvitation = (teamId) => {

        api.patch(`/teams/${teamId}/users/${user.id}`).then(() => {
            addNotification("Accepted invitation.");
            const newTeams = [...teams];
            for (const team of invitations) {
                team.status = "joined";
                if (team.id === teamId) {
                    newTeams.push(team);
                }
            }
            setTeams(newTeams);
            setInvitations(invitations.filter(team => team.id !== teamId));
         }).catch(() => {
            addNotification("Invitation no longer exists.");
         });

    }

    const rejectInvitation = (teamId) => {

        api.delete(`/teams/${teamId}/users/${user.id}`).then(() => {
            addNotification("Rejected invitation.");
            setInvitations(invitations.filter(team => team.id !== teamId));
         }).catch(() => {
            addNotification("Invitation no longer exists.");
         });

    }

    return (
        <div id="team-overview">

            <h2>Your teams.</h2>
            <p>Between these teams, you can share resources.</p>

            {teams.length > 0 &&
            
                <ul>
                    
                    {teams.map((team) => {
                        return (
                            <Link to={`/teams/${team.id}`} className="team block">
                                <h3>{team.name}</h3>
                                <p>Your role is <span>{team.is_owner ? "Owner" : "Member"}</span>.</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg>
                            </Link>
                        )
                    })}

                    {teams.length < 50 &&
                        <Link to="/teams/new" className="team block new">
                            <h3>Add team</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>
                        </Link>
                    }

                </ul>

            }

            {teams.length === 0 &&
                <Notice
                    title="No teams yet"
                    message="Start by creating a team."
                    href="/teams/new"
                    linkText="Create"
                    />
            }

            <h2>Your invitations.</h2>
            <p>Accept or reject any pending invitations.</p>

            {invitations.length > 0 &&
                <ul>
                    {invitations.map((team) => {
                        return (
                            <Link className="team block">
                                <h3>{team.name}</h3>
                                <div class="response">
                                    <Button 
                                        text="Reject" 
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>}
                                        onClick={() => rejectInvitation(team.id)}
                                        />
                                    <Button 
                                        text="Accept" 
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>}
                                        onClick={() => acceptInvitation(team.id)}
                                        cta />
                                </div>
                            </Link>
                        )
                    })}
                </ul>
            }

            {!invitations.length &&
                <p class="none">You don't have any invitations.</p>
            }

        </div>
    )

}

export default function TeamPage() {

    const location = useLocation();

    const [teamId, setTeamId] = useState(null);
    const [teams, setTeams] = useState([]);
    const [teamsInvitations, setTeamsInvitations] = useState([]);
    const [team, setTeam] = useState({});
    const [teamUsers, setTeamUsers] = useState([]);
    

    const { team_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (team_id !== "new") setTeamId(team_id);
    }, [team_id, location.pathname]);

    useEffect(() => {
       
        if (!teamId) {
            
            api.get("/teams").then(response => {
                const teams = response.data.data.teams;
                setTeams(teams.filter(team => team.status === "joined"));
                setTeamsInvitations(teams.filter(team => team.status === "invited"));
            }).catch(() => {});

        } else {

            api.get(`/teams/${teamId}`).then(response => {
                setTeam(response.data.data.team);
            }).catch(() => {
                navigate("/teams");
            });
            
            api.get(`/teams/${teamId}/users`).then(response => {
                setTeamUsers(response.data.data.team.users);
            }).catch(() => {});

        }
 
    }, [teamId]);

    const teamNew = location.pathname.replace(/\/$/, "").endsWith("new");

    return (
        <div 
            id="teams"
            >

            <PageTitle 
                title={team.name ? team.name : (teamNew ? "New team" : "Teams.")}
                breadcrumb={team.name || teamNew ? [{href: '/teams', text: 'Teams'}] : null}
                stats={!teamId && !teamNew ? [
                    {type: "teams", "used": teams.length, "available": 50},
                    {type: "invitations", "used": teamsInvitations.length, "available": 50}
                ] : (!teamNew ? [
                    {type: "users", "used": teamUsers.length, "available": 50}
                ] : [])}
                />
            
            <div
                className={"container"}
                >

                {teamId && <TeamEdit team={team} teamUsers={teamUsers} setTeamUsers={setTeamUsers} />}

                {!teamId && !teamNew && <Teams teams={teams} setTeams={setTeams} invitations={teamsInvitations} setInvitations={setTeamsInvitations} />}

                {teamNew && <TeamCreate /> }
                
            </div>

        </div>
    )
    
}