import axios from 'axios';
import Cookies from 'universal-cookie';

export const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_BASE_URL;
  } else {
    return '';
  }
};

const BASE_URL = getBaseUrl();

const api = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
  timeout: 25000,
});

export const loginApi = axios.create({
  baseURL: `${BASE_URL}/auth/login`,
  headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
  timeout: 5000,
});

export const logoutApi = axios.create({
  baseURL: `${BASE_URL}/auth/logout`,
  headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
  timeout: 5000,
});

const refreshApi = axios.create({
  baseURL: `${BASE_URL}/auth/refresh`,
  headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
  timeout: 5000,
});

let navigate = null;

// Function to set the navigate function from a component
export const setNavigate = (navigateFunc) => {
  navigate = navigateFunc;
};

// Set up your interceptor here
api.defaults.withCredentials = true;
loginApi.defaults.withCredentials = true;
refreshApi.defaults.withCredentials = true;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

api.interceptors.response.use(
  response => response,
  async error => {

    if (["/signup"].includes(window.location.pathname)) return Promise.reject(error);

    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then(() => {
          return api(originalRequest);
        })
        .catch(err => {
          if (!["/login", "/signup"].includes(window.location.pathname)) {
            navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
          }
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refreshApi.post('')
          .then(({ data }) => {
            processQueue(null);
            resolve(api(originalRequest));
          })
          .catch((err) => {
            processQueue(err);
            reject(err);
            if (!["/login", "/signup"].includes(window.location.pathname)) {
              navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      });

    // get a 404 on a retry of the original
    } else if (error.response && error.response.status === 404 && failedQueue.length > 0) {
      
      processQueue(error);
      navigate('/login');

    }

    return Promise.reject(error);
  }
);


api.interceptors.request.use((config) => {

  const cookies = new Cookies();
  config.headers['X-CSRF-TOKEN'] = cookies.get('csrf_access_token');

  return config;
}, (error) => {
  return Promise.reject(error);
});

refreshApi.interceptors.request.use((config) => {
  
  const cookies = new Cookies();
  config.headers['X-CSRF-TOKEN'] = cookies.get('csrf_refresh_token');
  return config

}, (error) => {
  return Promise.reject(error);
})


export default api;