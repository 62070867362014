import { useEffect } from 'react';

const useCtrlSSubmit = (submitFunction) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        submitFunction();
      }
    };

    // Mount: Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Unmount: Remove event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [submitFunction]);
};

export default useCtrlSSubmit;