import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    
    const [notifications, setNotifications] = useState([]);

    const addNotification = useCallback((message, error=null) => {

        const id = new Date().getTime();
        const duration = 5000;
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            { id, message, fading: false, startTime: id, error: error }
        ]);

        setTimeout(() => {
            removeNotification(id);
        }, duration);

    }, []);

    const removeNotification = useCallback((id) => {
        
        setNotifications((prevNotifications) =>
            prevNotifications.map(notification =>
                notification.id === id ? { ...notification, fading: true } : notification
            )
        );

        setTimeout(() => {
            setNotifications((prevNotifications) =>
                prevNotifications.filter(notification => notification.id !== id)
            );
        }, 300);

    }, []);

    const frameIdRef = useRef();

    return (
        <NotificationContext.Provider value={{ addNotification, removeNotification, notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {

    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;

};