import { useEffect, useRef, useState } from 'react';
import TextInput from '../inputs/TextInput';
import FileInput from '../inputs/FileInput';
import Papa from 'papaparse';
import { SingleSelect } from '../inputs/Select';
import api from '../../common/api';

import './FeedBuilder.scss';


export default function FeedBuilder(props) {

   const FEED_ENTRIES_MAXLENGTH = 100;

   const [items, setItems] = useState([]);
   const [errors, setErrors] = useState({});
   const [deletedItemIds, setDeletedItemIds] = useState([]);
   const [value, setValue] = useState(JSON.stringify({insert: [], update: [], delete: []}));
   const [editingTarget, setEditingTarget] = useState(null);
   const [inputDefaultValues, setInputDefaultValues] = useState({text: null, url: null, title: null})

   // files
   const [files, setFiles] = useState([]);
   const [fileMap, setFileMap] = useState({});

   const addSearchKeyRef = useRef(null);
   const addSearchValueRef = useRef(null);
   const addSearchValueTitleRef = useRef(null);

   useEffect(() => {
      if (props.items) {
         const newItems = [...props.items];
         setItems(newItems);
      };
   }, [props.items]);

   useEffect(() => {

      if (!props.assistant_id) return;

      api.get(`/assistants/${props.assistant_id}/files`).then(response => {
         const responseFiles = response.data.data.files;
         setFiles(responseFiles.map(file => ({value: file.id, showValue: file.remote_name.split("/").pop()})))
         
         const newFileMap = {};
         for (const file of responseFiles) {
            newFileMap[file.id] = file.remote_name.split("/").pop();
         }
         setFileMap(newFileMap);

      });

   }, []);

   const updateValue = (items, deletedItemIds) => {
      
      setValue(JSON.stringify(
         {
            insert: items.filter(item => item.text !== null && !item.id).map(({temp_id, ...item}) => item), 
            update: items.filter(item => item.id).map(({vector, temp_id, ...item}) => item),
            delete: deletedItemIds
         }
      ));

   }

   const handleCSVItems = (files, setSelectedFiles) => {

      const EXPECTED_COLUMNS = ["key", "value", "title"];
      const MAX_LENGTHS = {key: 1000, value: 200, title: 50};

      const validateHeaders = (headers) => {
         if (!EXPECTED_COLUMNS.every(col => headers.includes(col))) {
           throw new Error('CSV does not contain all required columns');
         }
       };
     
       const validateRow = (row) => {
         for (const col of EXPECTED_COLUMNS) {
           if (typeof row[col] !== 'string') {
             throw new Error(`Column ${col} must be a string`);
           }
           if (row[col].length > MAX_LENGTHS[col]) {
             throw new Error(`Column ${col} exceeds maximum length of ${MAX_LENGTHS[col]} characters`);
           }
         }
       };

      if (files.length === 0) return; 

      const reader = new FileReader();
      reader.onload = (e) => {
         const csv = e.target.result;
         Papa.parse(csv, { 
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
            try {
               if (results.errors.length > 0) {
                  throw new Error('CSV parsing error: ' + results.errors[0].message);
               }

               validateHeaders(results.meta.fields);
               results.data.forEach(validateRow);

               const newItems = [...items, ...results.data.map(row =>
                  ({text: row.key, url: row.value, title: row.title, temp_id: Math.random().toString(36).substring(2, 12)})
               ).slice(0, FEED_ENTRIES_MAXLENGTH - items.length)]
               setItems(newItems);
               updateValue(newItems, deletedItemIds);
               
            } catch (error) {
               console.log(error);
            }
            },
            error: (error) => {
               console.log(error);
            }
         }); 

      };
      reader.readAsText(files[0]);
      setSelectedFiles([]);

   }

   const addItem = () => {

      if (FEED_ENTRIES_MAXLENGTH <= items.length) return;
      
      const tempId = Math.random().toString(36).substring(2, 12);
      const newItems = [...items];
      newItems.push({text: null, url: null, title: null, tempId: tempId});
      setItems(newItems);
      setEditingTarget(null);
      setTimeout(() => { addSearchKeyRef.current.focus() }, 150);

   }

   const deleteItem = (key) => {
   
      const newItems = [...items].filter((_, index) => key !== index);
      if (items[key].id) {
         const newDeletedItemIds = [...deletedItemIds];
         newDeletedItemIds.push(items[key].id);
         setDeletedItemIds(newDeletedItemIds);
         updateValue(newItems, newDeletedItemIds);
      }
      setItems(newItems);
      setEditingTarget(null);

   }

   const updateItem = (key) => {
      
      // check if all values are filled
      const newErrors = {};
      for (const input of [addSearchKeyRef.current, addSearchValueRef.current, addSearchValueTitleRef.current]) {
         if (input.value === "") {
            newErrors[input.getAttribute("name")] = "Fill in this value.";
         }
      }

      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;

      const newItem = {
         text: addSearchKeyRef.current.value,
         url: addSearchValueRef.current.value,
         title: addSearchValueTitleRef.current.value,
         id: items[key].id,
         temp_id: items[key].temp_id
      };

      // add to displayed items
      const newItems = [...items];
      newItems[key] = newItem;
      if (FEED_ENTRIES_MAXLENGTH > items.length) {
         const tempId = Math.random().toString(36).substring(2, 12);
         newItems.push({text: null, url: null, title: null, tempId: tempId});
         setTimeout(() => { addSearchKeyRef.current.focus() }, 500);
      }
      setEditingTarget(null);
      setItems(newItems);
      updateValue(newItems, deletedItemIds);

   }

   return (
      <div 
         id="index-builder"
         className={props.readOnly ? "readonly" : ""}
         >
         
         <ul>

            <li>
               <span>Search key</span>
               <span>Value</span>
               <span>Value title</span>
               {!props.readOnly && <><span></span><span></span></>}
            </li>
            <li>
               <span>Search query is compared to this key</span>
               <span>Value that is retrieved</span>
               <span>Title to describe what the value is</span>
               {!props.readOnly && <><span></span><span></span></>}
            </li>
            
            {items.length === 0 && <li><p>There are no items yet. {!props.readOnly && <><span className="add-link" onClick={addItem}>Add one</span>.</>}</p></li>}

            {items.map((item, key) => {

               if (item.text === null || editingTarget === key) {

                  return (
                     <li 
                        key={item.id || item.temp_id}
                        className={"add" + (
                           errors.text || errors.url || errors.title ? " error" : ""
                        )}>
                        <TextInput
                           id="edit-search-key"
                           name="text"
                           innerRef={addSearchKeyRef}
                           defaultValueControlled={item.text}
                           example="Phrase to search by"
                           onKeyDown={inputDefaultValues.text !== null ? () => setInputDefaultValues({...inputDefaultValues, text: null}): null}
                           inputCallback={() => setErrors(prevErrors => ({...prevErrors, text: null}))}
                           error={errors.text}
                           maxLength={1000}
                           />
                        
                        {props.type === "text" &&
                           <TextInput
                              id="edit-search-value"
                              name="url"
                              defaultValueControlled={item.url}
                              innerRef={addSearchValueRef}
                              onKeyDown={inputDefaultValues.url !== null ? () => setInputDefaultValues({...inputDefaultValues, url: null}) : null}
                              example="Value to retrieve"
                              inputCallback={() => setErrors(prevErrors => ({...prevErrors, url: null}))}
                              error={errors.url}
                              maxLength={200}
                              />
                        }

                        {props.type === "file" &&
                           <SingleSelect
                              id="edit-search-value"
                              name="url"
                              defaultValueControlled={item.url}
                              innerRef={addSearchValueRef}
                              onFocus={inputDefaultValues.url !== null ? () => setInputDefaultValues({...inputDefaultValues, url: null}) : null}
                              placeholder="File"
                              inputCallback={() => setErrors(prevErrors => ({...prevErrors, url: null}))}
                              error={errors.url}
                              choices={files}
                              search
                              />
                        }

                        <TextInput
                           id="edit-search-value-title"
                           name="title"
                           innerRef={addSearchValueTitleRef}
                           defaultValueControlled={item.title}
                           example="Descriptive title of value"
                           error={errors.title}
                           inputCallback={() => setErrors(prevErrors => ({...prevErrors, title: null}))}
                           onKeyDown={(e) => {
                              if (["Enter", "Tab"].includes(e.key)) {
                                 e.preventDefault();
                                 updateItem(key);
                              }
                           }}
                           maxLength={50}
                           />
                        <span
                           onClick={() => updateItem(key)}
                           >
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
                        </span>
                        <span
                           onClick={() => {
                              deleteItem(key);
                           }}
                           >
                           <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" viewBox="0 0 24 24" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"/></svg>
                        </span>
                     </li>
                  )

               }

               const value = props.type === "file" ? fileMap[item.url] : item.url

               return(
                  <li
                     key={key}
                     >
                     <span>{item.text}</span>
                     <span>{value}</span>
                     <span>{item.title}</span>

                     {!props.readOnly &&
                     
                        <>

                           <span
                              onClick={() => {
                                 setEditingTarget(key);
                                 const newItems = [...items].filter(item => item.text !== null)
                                 setItems(newItems);
                              }}
                              >
                              <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.134 19.319 11.587-11.588c.171-.171.279-.423.279-.684 0-.229-.083-.466-.28-.662l-3.115-3.104c-.185-.185-.429-.277-.672-.277s-.486.092-.672.277l-11.606 11.566c-.569 1.763-1.555 4.823-1.626 5.081-.02.075-.029.15-.029.224 0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342 2.137 2.137-3.168 1.046zm.955-1.166 10.114-10.079 2.335 2.327-10.099 10.101z" fill-rule="nonzero"/></svg>
                           </span>
                           <span
                              onClick={() => {
                                 deleteItem(key);
                              }}
                              >
                              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" viewBox="0 0 24 24" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"/></svg>
                           </span>

                        </>
                     }

                  </li>
               );
            })}

         </ul>

         {FEED_ENTRIES_MAXLENGTH > items.length && items.length > 0 && items[items.length - 1].text !== null && !props.readOnly && editingTarget === null &&
            <p><span className="add-link" onClick={addItem}>Add new item</span></p>
         }

         {!props.readOnly && items.length < FEED_ENTRIES_MAXLENGTH &&
            <div id="index-file-input-wrapper">
               <FileInput 
                  maxFiles={1}
                  maxSizePerFile={20 * 1024 * 1024}
                  allowedExtensions={[".csv"]}
                  onChange={handleCSVItems}
                  noRenderFiles
                  />
               <p>Please ensure your file has a 'key', 'value', and 'title' column.</p>
            </div>
         }

         {items.length >= FEED_ENTRIES_MAXLENGTH &&
            <p id="index-max-reached">The index has the maximum number ({FEED_ENTRIES_MAXLENGTH}) of items.</p>
         }

         {!props.readOnly && <input type="hidden" value={value} name={props.name} />}

      </div>
   )

}