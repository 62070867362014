import { useLocation } from 'react-router';
import './PageTitle.scss';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function PageTitle(props) {

    const location = useLocation();
    const [title, setTitle] = useState(null);
    const [showIndex, setShowIndex] = useState(null);

    const currentIndexRef = useRef(null);
    const timeoutRef = useRef(null);

    const onTouchGroup = useCallback((key) => {

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw > 972 || key === currentIndexRef.current) return;
        const newKey = key === currentIndexRef.current ? null : key;
        setShowIndex(newKey);
        currentIndexRef.current = newKey;
        
    }, []);

    const onTouchClose = useCallback((key) => {

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw > 972 || currentIndexRef.current !== key) return;
        setTimeout(() => {
            setShowIndex(null);
            currentIndexRef.current = null;
        }, 50);
        
    }, []);

    const onHoverGroup = useCallback((key) => {

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw <= 972) return;
        if (key !== showIndex) {
            clearTimeout(timeoutRef.current);
            setShowIndex(key);
            currentIndexRef.current = key;
        }

    }, []);

    const onLeaveGroup = useCallback((key) => {

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw <= 972) return;
        timeoutRef.current = setTimeout(() => {
            if (currentIndexRef.current === key) {
                setShowIndex(null);
            }
        }, 300);

    }, []);

    useEffect(() => {
        if (!props.links) return;
        for (const link of props.links) {
            if (location.pathname.startsWith(link.to)) {
                setTitle(link.text + ".");
                return;
            }
        } 
    }, [location.pathname]);

    return (
        <div 
            id="page-title-wrapper"
            >

            <div 
                id="page-title"
                className="container"
                >

                <h1>
                    {props.breadcrumb && props.breadcrumb.map((crumb, key) => {
                        return (<><Link to={crumb.href} key={key}>{crumb.text}</Link><span>/</span></>)
                    })}
                    {title || props.title}
                    {props.stats &&
                        <span className="stats">
                            {props.stats.map((stat, key) => {
                                if (!stat) return; 
                                return (
                                    <span key={key}>{stat.used} / {stat.available} {stat.type}</span>
                                )
                            })}
                        </span>
                    }
                </h1>

            </div>

            {props.links &&
            
                <div id="page-nav-wrapper">

                    <div className="container">

                        <ul id="page-nav">

                            {props.links.map((link, outerKey) => {
                                return (
                                    <li
                                        className={
                                            (location.pathname + location.search === link.to ? "active" : "") +
                                            (link.inactive ? " inactive" : "") +
                                            (link.items ? " group": "") +
                                            (showIndex === outerKey ? " show": "")
                                        }
                                        key={outerKey}
                                        onTouchStart={() => onTouchGroup(outerKey)}
                                        onMouseOver={() => onHoverGroup(outerKey)}
                                        onMouseLeave={() => onLeaveGroup(outerKey)}
                                        >
                                        
                                        {link.items &&
                                        
                                            <>
                                                
                                                <span
                                                    onTouchStart={() => onTouchClose(outerKey)}
                                                >
                                                    {link.icon}
                                                    {link.text}
                                                    <svg className="chevron" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                                                </span>

                                                <ul>
                                                    {link.items.map((link, key) => {

                                                        return (
                                                            <li key={key} >
                                                                <Link 
                                                                    key={key} 
                                                                    to={link.to} 
                                                                    className={link.inactive ? " inactive" : ""}
                                                                    onTouchStart={() => {
                                                                        setTimeout(() => onTouchClose(outerKey), 150)
                                                                    }}
                                                                    onClick={() => {
                                                                        setTitle(link.text + ".");
                                                                        onLeaveGroup(outerKey);
                                                                    }}>
                                                                    {link.icon}
                                                                    {link.text}
                                                                </Link>
                                                            </li>
                                                        );

                                                    })}

                                                </ul>

                                            </>

                                        }

                                        {!link.items &&

                                            <Link key={outerKey} to={link.to} onClick={() => setTitle(link.text + ".")}>
                                                {link.icon}
                                                {link.text}
                                            </Link>

                                        }

                                    </li>
                                )
                            })}

                        </ul>

                    </div>

                </div>

        }


        </div>
    );

}