import './AreYouSure.scss';
import React, { useRef, useState } from 'react';
import Button from './inputs/Button';
import api from '../common/api';
import TextInput from './inputs/TextInput';

const AreYouSure = ({ url, children, isOpen, setIsOpen, verySure, successCallback, noButtons, deleteUrl }) => {

   const verySureRef = useRef(null);
   const [IAmSureError, setIAmSureError] = useState(null);
   const [loading, setLoading] = useState(false);

   const handleConfirm = async () => {

      if (verySure && verySureRef.current.value !== "i-am-sure") {
         setIAmSureError("If you wish to confirm, please type the confirmation value.")
         return;
      }

      setLoading(true);

      const action = deleteUrl ? api.delete : api.post;
      action(url).then(() => {

      }).catch(() => {}).finally(() => {
         setIsOpen(false)
         setLoading(false);
         if (successCallback) successCallback();
      });
   };


   const handleCancel = () => {
      setIsOpen(false);
   };

   return (
      <div 
         id="are-you-sure-wrapper"
         className={isOpen ? " open" : ""}
         >
         <div
            id="are-you-sure"
            className={"block"}
         >
            <div>{children}</div>

            {verySure &&
               <>
                  <p>Please type the following to confirm: <span className="i-am-sure">i-am-sure</span></p>
                  <TextInput
                     placeholder="Confirmation message" 
                     innerRef={verySureRef}
                     error={IAmSureError}
                     inputCallback={() => setIAmSureError(null) }
                     />
               </>
            }

            {!noButtons &&
               <div className="buttons">
                  <Button text="Cancel" onClick={handleCancel} cta icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>} />
                  <Button text="Confirm" loading={loading} onClick={handleConfirm} icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>} />
               </div>
            }

         </div>
      </div>
   );
};

export default AreYouSure;