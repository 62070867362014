import { useEffect, useState } from "react";
import TextInput from "../inputs/TextInput";
import Button from "../inputs/Button";
import { SingleSelect } from "../inputs/Select";

import './LocationsBuilder.scss';

import getDefaultCountries from '../../common/countries';
import api from "../../common/api";

export default function LocationsBuilder({defaultLocations, loading}) {

    const MAX_LOCATIONS = 5;
    const FIELDS_PRETTY_NAMES = {
        street: "Street", housenumber: "House #", zipcode: "Zip code", city: "City"
    }

    const [locations, setLocations] = useState([]);
    const [locationsDeleted, setLocationsDeleted] = useState([]);
    

    const addLocation = () => {

        if (locations.length >= MAX_LOCATIONS) return;

        const tempId = Math.random().toString(36).substring(2, 12);
        setLocations(prevLocations => ([
            ...prevLocations,
            {street: "", housenumber: "", zipcode: "", city: "", country: "NL", temp_id: tempId, newLocation: true}
        ]))

    }

    const updateLocation = (index, field, value) => {

        if (locations[index].id) return;
        const newLocations = locations.map((item, itemKey) => index === itemKey ? { ...item, [field]: value } : item);
        setLocations(newLocations);
  
    }

    const requestLocationCity = (index) => {

        const location = locations[index];
        const body = {street: location.street, housenumber: location.housenumber, zipcode: location.zipcode, country: location.country};
        for (const key of Object.keys(body)) {
            if (!body[key]) return;
        }

        api.post("/locations/:getcity", body).then(response => {
            console.log(response);
            updateLocation(index, "city", response.data.data.city);
        });

    }

    const deleteLocation = (index) => {

        if (locations[index].id) {
            setLocationsDeleted([...locationsDeleted, locations[index].id]);
         }
   
         setLocations(prevLocations => (prevLocations.filter((_, itemKey) => index !== itemKey)));

    }

    useEffect(() => {
        if (defaultLocations) {
            setLocations(defaultLocations);
        }
    }, [defaultLocations]);

    return (
        <div id="locations-builder">

            <h2>Physical locations.</h2>
            <p>Providing one or more physical locations gives your assistant information about where you're located.</p>

            <div id="locations-container">
                {locations.map((location, index) => {

                    return (
                        <div className="location" key={location.temp_id || location.id}>

                            <SingleSelect
                                choices={getDefaultCountries()}
                                placeholder="Country"
                                defaultValueControlled={location.country || 'NL'}
                                readOnly={!location.newLocation}
                                changeCallback={(value) => updateLocation(index, "country", value)}
                                search
                                noWhiteBackground
                                />

                            {[["street", "housenumber"], ["zipcode", "city"]].map((fieldGroup, outerKey) => {

                                return (
                                    <div className="location-fields" key={index + '-' + outerKey}>
                                        
                                        {fieldGroup.map((field, innerKey) => {

                                            return (

                                                <TextInput 
                                                    placeholder={FIELDS_PRETTY_NAMES[field]}
                                                    key={index + '-' + outerKey + '-' + innerKey}
                                                    defaultValueControlled={locations[index][field]}
                                                    inputCallback={(_, value) => updateLocation(index, field, value)}
                                                    readOnly={!location.newLocation || field === "city"}
                                                    blurCallback={() => requestLocationCity(index)}
                                                    autoComplete="no"
                                                    noWhiteBackground
                                                    />

                                            )

                                        })}

                                    </div>
                                );

                            })}

                            <div className="location-buttons">
                                <span>Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a></span>
                                <span onClick={() => deleteLocation(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 6l-3 18h-12l-3-18h2.028l2.666 16h8.611l2.666-16h2.029zm-4.711-4c-.9 0-1.631-1.099-1.631-2h-5.316c0 .901-.73 2-1.631 2h-5.711v2h20v-2h-5.711z"/></svg>
                                    Delete
                                </span>
                            </div>

                        </div>
                    )

                })}
                
            </div>

            <Button 
               text="Add location"
               disabled={loading || locations.length >= MAX_LOCATIONS || locations.some(obj => Object.values(obj).some(val => !val))}
               onClick={() => addLocation()}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>}
            />

            
            <input type="hidden" name="locations" value={JSON.stringify(
                locations
                    .filter(location => location.newLocation && location.city)
                    .map(({temp_id, newLocation, ...location}) => location)
            )} />
            <input type="hidden" name="locations_delete" value={locationsDeleted.join(",")} />

        </div>
    );

}