import { Navigate, Route, Router, Routes, useLocation, useNavigate } from 'react-router';

import ChatPage from './pages/ChatPage';
import Nav from './components/Nav';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import OverviewPage from './pages/AssistantsPage';
import AssistantPage from './pages/AssistantPage';
import MetricsPage from './pages/MetricsPage';
import AccountPage from './pages/AccountPage';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationContainer from './components/NotificationContainer';
import useViewportHeight from './hooks/useViewportHeight';
import { useEffect } from 'react';
import { setNavigate } from './common/api';
import SignupPage from './pages/SignupPage';

import FeedPage from './pages/FeedPage';
import IntegrationPage from './pages/IntegrationPage';
import { InputVariablesProvider } from './contexts/InputVariablesContext';
import { DarkModeProvider } from './contexts/DarkModeProvider';
import TeamPage from './pages/TeamPage';
import AdminPage from './pages/AdminPage';


function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const hideNav = location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/logout');

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useViewportHeight();

  return (

    <>
    
    <DarkModeProvider>
      <NotificationProvider>
        <AuthProvider>
          <InputVariablesProvider>

            <Nav 
              hide={hideNav}
              />

            <main
              className={hideNav ? "full-width" : ""}
              >

                <Routes>
                  <Route path="/" element={<Navigate to="/assistants" replace />} />
                  <Route path="/assistants" element={<OverviewPage />} />
                  <Route path="/assistants/:assistant_id" element={<AssistantPage />} />
                  <Route path="/assistants/new" element={<AssistantPage new />} />
                  <Route path="/assistants/:assistant_id/chats" element={<ChatPage />} />
                  <Route path="/assistants/:assistant_id/chats/:chat_id" element={<ChatPage />} />
                  <Route path="/assistants/:assistant_id/integrations/:integration_id" element={<IntegrationPage />} />
                  <Route path="/assistants/:assistant_id/feeds/new" element={<FeedPage new />} />
                  <Route path="/assistants/:assistant_id/feeds/:feed_id" element={<FeedPage />} />
                  <Route path="/metrics" element={<MetricsPage />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/account/*" element={<AccountPage />} />
                  <Route path="/teams" element={<TeamPage />} />
                  <Route path="/teams/:team_id" element={<TeamPage />} />
                  <Route path="/users" element={<AdminPage />} />
                  <Route path="/users/:user_id" element={<AdminPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/logout" element={<LoginPage />} />
                </Routes>

                <NotificationContainer />

              </main>

            </InputVariablesProvider>
          </AuthProvider>
        </NotificationProvider>
      </DarkModeProvider>
    
    </>

  );
}

export default App;
