import { useEffect, useState } from 'react';
import './BigCheckbox.scss';

export default function BigCheckbox({id, name, title, text, value, defaultChecked, icon, tag, disabled, onClick, onChange}) {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (defaultChecked || defaultChecked === false) {
            setChecked(defaultChecked)
        }
    }, [defaultChecked]);

    useEffect(() => {
        if (disabled) setChecked(false);
    }, [defaultChecked, disabled]);

    useEffect(() => {
        if (onChange) onChange(checked);
    }, [checked]);

    return (
        <div 
            className={"big-checkbox" + 
                (checked ? " checked" : "") +
                (icon ? " icon" : "") +
                (disabled ? " disabled" : "")
            }
            onClick={() => { 
                setChecked(!checked); 
                if (onClick) onClick();
            }}
            >

            <input 
                id={id}
                type="checkbox"
                name={name}
                value={value || "yes"}
                defaultChecked={defaultChecked}
                checked={checked}
                />
            <label
                htmlFor={id}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
            </label>

            <p className="big-checkbox-text">
                {title && <span className="title">{title}</span>}
                {text}
            </p>

            {tag &&
                <span
                    className="big-checkbox-tag"
                    >
                    {tag}
                </span>
            }

            {icon &&
                <div className="big-checkbox-icon">
                    {icon}
                </div>
            }

        </div>
    )

}