import { useEffect, useState } from "react";
import AreYouSure from "../AreYouSure";
import Button from "../inputs/Button";
import { Link, useLocation } from "react-router-dom";
import api from "../../common/api";
import { useNotifications } from "../../contexts/NotificationContext";

import './FeedsOverview.scss';
import './Overview.scss';

export default function FeedsOverview({assistantId}) {

    const [loading, setLoading] = useState(false);
    const [feeds, setFeeds] = useState([]);

    const [areYouSureShow, setAreYouSureShow] = useState(false);
    const [areYouSureUrl, setAreYouSureUrl] = useState(null);
    const [areYouSureId, setAreYouSureId] = useState(null);
    const [areYouSureAction, setAreYouSureAction] = useState(null);

    const { addNotification } = useNotifications();
    const location = useLocation();
    
    useEffect(() => {

        if (!assistantId) return;

        setLoading(true);
        setFeeds([]);
        api.get(`/assistants/${assistantId}/feeds`)
            .then((response) => {
                setLoading(false);
                setFeeds(response.data.data.feeds);
            })
            .catch(() => {
                setLoading(false);
            });

    }, [assistantId]);

    return (

        <>

            <div id="feeds" className="overview">

                <h2>Available feeds.</h2>
                <p>{feeds.length ? `${feeds.length} available` : "There are no files available."}</p>

                <div 
                    id="feed-container"
                    >
                    
                    {feeds.map((feed, key) => {

                        return (
                            <div
                                key={key} 
                                className="feed block"
                                >

                                <h3>{feed.name}</h3>
                                <p>{feed.description}</p>

                                <div className="feed-options">
                                    <input 
                                        id={`feed-options-${key}`}
                                        type="checkbox" 
                                    />
                                    <label
                                        className="hover-effect"
                                        htmlFor={`feed-options-${key}`}
                                        >
                                        <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z"/></svg>
                                    </label>
                                    <ul>

                                        <li>
                                            <Link to={`/assistants/${assistantId}/feeds/${feed.id}`} className="option">Edit</Link>
                                            <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fillRule="nonzero"/></svg>
                                        </li>
                                        <li
                                            >
                                            <span 
                                                className="option"
                                                onClick={() => {
                                                    setAreYouSureShow(true);
                                                    setAreYouSureUrl(`/assistants/${assistantId}/feeds/${feed.id}/:delete`);
                                                    setAreYouSureId(feed.id);
                                                }}
                                                >Delete</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg>
                                        </li>

                                    </ul>
                                </div>
                                
                                <Link to={`/assistants/${assistantId}/feeds/${feed.id}?tab=items`}>Edit entries<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M14.812 20.171c-.791.523-1.738.829-2.757.829-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.019-.306 1.966-.829 2.757l2.829 2.829-1.414 1.414-2.829-2.829zm-9.083-1.171c-3.528 0-5.729-2.65-5.729-5.5 0-2.702 1.952-4.945 4.521-5.408.212-3.951 3.474-7.092 7.479-7.092 4.006 0 7.267 3.141 7.479 7.092 2.57.463 4.521 2.706 4.521 5.408 0 2.881-2.261 5.5-5.62 5.5.433-.909.675-1.927.675-3 0-3.863-3.137-7-7-7-3.864 0-7 3.137-7 7 0 1.073.242 2.091.674 3m6.326-6c1.655 0 3 1.345 3 3s-1.345 3-3 3c-1.656 0-3-1.345-3-3s1.344-3 3-3"/></svg></Link>
                                
                                {feed.status &&
                                    <span className={"status" + (feed.status === "pending" ? " pending" : "")}>{feed.status}</span>
                                }

                            </div>
                        )
                    })}

                    {!loading && feeds.length < 3 &&

                        <div id="new-feed-wrapper">
                            <Button 
                                to={`/assistants/${assistantId}/feeds/new`}
                                text={"Create feed"}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>}
                                cta
                                />
                        </div>

                    }

                </div>  

            </div>

            {areYouSureId !== null &&

                <AreYouSure 
                    isOpen={areYouSureShow}
                    setIsOpen={setAreYouSureShow}
                    url={areYouSureUrl}
                    children={
                        <>
                            <h3>Are you sure?</h3>
                            <p>You are about to delete this feed. Please note that this cannot be undone and deleted feeds cannot be recovered.</p>
                        </>
                    }
                    verySure={true}
                    successCallback={() => { 
                        addNotification(`Successfully deleted feed.`); 
                        const newFeeds = [...feeds].filter(feed => feed.id !== areYouSureId);
                        setFeeds(newFeeds);
                    }}
                    noButtons={areYouSureAction === "transfer"}
                />

                }

        </>

    )
    
}